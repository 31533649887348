import React, { Fragment } from 'react'

import {
    Typography,
    ListItem,
    ListItemText,
    Grid,
} from '@material-ui/core'

const QuestionImage = ({
    question, choice, setChoice,
}) => {
    return (
        <Fragment>
            <Typography>
                {question.question}
            </Typography>
            <Grid style={{ marginTop: '1rem' }} container justify="center" spacing={1}>
                {Object.keys(question.answers).sort().map((key, i) =>
                    <Grid key={i} container item justify="center" xs={i === 2 ? 12 : 6} md={2}>
                        <Grid item xs={i === 2 ? 6 : 12} md={12}>
                            <ListItem button onClick={() => setChoice(key)} style={{ border: '1px dashed lightgray', borderRadius: '5px', backgroundColor: `${choice === key ? '#b6edb6' : 'inherit'}` }}>
                                <ListItemText><img style={{ objectFit: 'contain', width: '100%', maxHeight: '100px' }} src={process.env.PUBLIC_URL + '/images/' + question.answers[key] + '.png'} alt={question.answers[key]} /></ListItemText>
                            </ListItem>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Fragment >
    )
}

export default QuestionImage