import React, { useState, Fragment } from 'react'
import { useStoreActions } from 'easy-peasy'

import {
    TextField,
    Button,
    Collapse,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

import Panel from '../../shared/Panel'

const CreateOrganization = () => {
    const organisation = useStoreActions(actions => actions.organisation)

    const initValues = { name: '' }
    const [values, setValues] = useState(initValues)
    const [status, setStatus] = useState(null)
    const [open, setOpen] = useState(false)

    const onChange = (e) => setValues({ ...values, [e.target.name]: e.target.value })

    const onClose = () => {
        setOpen(false)
        setStatus(null)
        setValues(initValues)
    }

    const onCreate = () => {
        const validName = values.name.length > 1

        if (!validName) setStatus('name_too_short')
        else {
            setStatus('loading')
            organisation.create(values)
                .then(() => setStatus('Organisation créée.'))
                .catch(error => {
                    console.error(error)
                    setStatus("Erreur lors de la création de l'organisation")
                })
        }
    }

    return (
        <Fragment>
            <Collapse in={!open}>
                <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => setOpen(true)}
                    startIcon={<AddIcon />}
                    style={{ margin: '1rem 0' }}
                >Créer une organisation</Button>
            </Collapse>
            <Collapse in={open}>
                <Panel title="Nouvelle organisation :" button="Créer l'organisation" status={status} onClose={onClose} onSubmit={onCreate}>
                    <TextField value={values.name} name="name" label="Nom" placeholder="Nom de l'organisation" variant="outlined" onChange={onChange} size="small" fullWidth />
                </Panel>
            </Collapse>
        </Fragment>
    )
}

export default CreateOrganization