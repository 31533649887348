import React from 'react'
import { useStoreState } from 'easy-peasy'
import { Trans, useTranslation } from 'react-i18next'

import {
    Grid,
    Typography,
} from '@material-ui/core'

const WhoAmI = () => {
    const auth = useStoreState(state => state.auth)
    const email = auth.user?.email

    if (!auth.isLogged) return null

    return (
        <Grid item xs={12} style={{ textAlign: 'right', color: 'gray' }}>
            <Typography variant="body2" >
                <Trans i18nKey="dashboard.whoami">
                    Vous êtes connecté en tant que <strong>{{ email }}</strong>
                </Trans>
            </Typography>
        </Grid>
    )
}

export default WhoAmI
