import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const MyTabs = withStyles((theme) => ({
    root: {
        borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
        backgroundColor: theme.palette.primary.main,
        // display: 'none'
    },
}))(Tabs)

const MyTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        minWidth: 72,
        padding: '0 1rem',
        border: '1px solid #fafafa',
        '&:hover': {
            color: theme.palette.primary.main,
            opacity: 1,
        },
        '&$selected': {
            color: theme.palette.primary.main,
            border: '1px solid #e8e8e8',
            borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px',
            backgroundColor: '#f0f0f0',
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: theme.palette.primary.main,
        },
    },
    selected: {},
}))((props) => <Tab {...props} />)

const MyTabPanel = (props) => {
    const { content, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            {...other}
        >
            {content}
        </div>
    );
}

const Tabulation = ({ content }) => {
    const [value, setValue] = React.useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    return (
        <div style={{ margin: '1rem 0' }}>
            <MyTabs value={value} onChange={handleChange} style={{ margin: '1rem 0', padding: '0 1rem' }}>
                {content.map((entry, i) =>
                    <MyTab key={i} label={entry.label} />
                )}
            </MyTabs>
            {content.map((entry, i) =>
                <MyTabPanel key={i} value={value} index={i} content={entry.panel} />
            )}
        </div>
    )
}

export default Tabulation