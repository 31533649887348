import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Dashboard from './Dashboard'
import Info from './Info'
import Start from './Start'
import Select from './Select'
import Questionnaire from './Questionnaire'
import End from './End'

const UserIndex = () => {
    return (
        <Switch>
            <Route exact path="/info" component={Info} />
            <Route exact path="/start" component={Start} />
            <Route exact path="/select" component={Select} />
            <Route exact path="/questionnaire" component={Questionnaire} />
            <Route exact path="/end" component={End} />
            <Route component={Dashboard} />
        </Switch>
    )
}

export default UserIndex
