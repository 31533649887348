import React, { useEffect, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { useStoreState, useStoreActions } from 'easy-peasy'

import {
    Typography,
    Grid,
    // Divider,
} from '@material-ui/core'

// import { Back } from '../../shared/ActionIcon'
import Tabulation from '../../shared/Tabulation'

import Members from './Members'
import Tests from './Tests'
import Credits from './Credits'

const ViewOrganisation = ({ history, match }) => {
    const organisation = useStoreActions(actions => actions.organisation)
    const state = useStoreState(state => state.organisation)
    const organisationId = match.params.orgId

    useEffect(() => {
        organisation.setCurrent(organisationId)
    }, [organisation, organisationId])

    return (
        <Fragment>
            <Grid container alignItems="center">
                {/* <Grid item style={{ padding: '0 1rem' }}>
                    <Back action={() => history.goBack()} />
                </Grid>
                <Divider orientation="vertical" flexItem /> */}
                <Grid item style={{ padding: '0 1rem' }}>
                    <Typography color="primary"><strong>{state.current?.name}</strong></Typography>
                </Grid>
            </Grid>
            <Tabulation content={[
                { label: 'Membres', panel: <Members /> },
                { label: 'Tests', panel: <Tests /> },
                { label: 'Credits', panel: <Credits /> },
            ]} />
        </Fragment>
    )
}

export default withRouter(ViewOrganisation)