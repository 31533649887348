import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useStoreState, useStoreActions } from 'easy-peasy'

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@material-ui/core'
import PageviewIcon from '@material-ui/icons/Pageview'
import DeleteIcon from '@material-ui/icons/Delete'

import TableOptions from '../../shared/TableOptions'

const Organization = ({ history }) => {
    const organisation = useStoreActions(actions => actions.organisation)
    const state = useStoreState(state => state.organisation)

    const remove = (org) => {
        if (window.confirm('Êtes-vous sur de vouloir supprimer cette organisation ?'))
            organisation.delete(org)
    }

    useEffect(() => {
        const unsubscribe = organisation.listAll()
        return () => unsubscribe()
    }, [organisation])

    return (
        <TableContainer style={{ margin: '1rem 0' }} component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Nom</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {state.list?.map((organisation, i) => (
                        <TableRow key={i} hover>
                            <TableCell style={{ cursor: 'pointer' }} onClick={() => history.push(`/manager/organisation/${organisation.id}`)}>{organisation.name}</TableCell>
                            <TableCell align="right">
                                <TableOptions options={[
                                    { label: "View organisation", icon: <PageviewIcon />, action: () => history.push(`/manager/organisation/${organisation.id}`) },
                                    { label: "Delete organisation", icon: <DeleteIcon />, action: () => remove(organisation) },
                                ]} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default withRouter(Organization)