import React, { useState, Fragment, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { useTranslation } from 'react-i18next'

import {
    Typography,
    Button,
    Backdrop,
    CircularProgress
} from '@material-ui/core'

import QuestionText from './QuestionText'
import QuestionImage from './QuestionImage'

const Question = ({
    history,
}) => {
    const { t } = useTranslation()
    const state = useStoreState(state => state.test)
    const actions = useStoreActions(actions => actions.test)

    const [answer, setAnswer] = useState(null)
    const [loading, setLoading] = useState(true)

    const onClick = () => {
        setLoading(true)
        actions.saveAnswer(answer)
            .then(() => {
                setAnswer(null)
                if (state.isTestCompleted) history.push('/test/submit')
                else actions.loadQuestion()
            })
    }

    useEffect(() => {
        actions.loadQuestion()
            .then(() => setLoading(false))
            .catch(error => {
                if (error === 'test_completed') history.push('/test/submit')
                else if (error === 'not_loaded_yet') return
                else history.push('/my/dashboard')
            })
    }, [state.currentTest, actions, history])

    useEffect(() => {
        setLoading(true)
        actions.startTest()
    }, [actions])

    if (!state?.currentQuestion) return null

    return (
        <Fragment>
            <Typography component="h1" variant="h4" align="center" color="textPrimary" gutterBottom>
                {t('question.title', { count: state.questionsCount, total: state.questionsTotal})}
            </Typography>
            {state.currentQuestion.type === 'text' && <QuestionText question={state.currentQuestion} answer={answer} setAnswer={setAnswer} />}
            {state.currentQuestion.type === 'image' && <QuestionImage question={state.currentQuestion} answer={answer} setAnswer={setAnswer} />}
            <div style={{ textAlign: 'center', marginTop: '1rem' }}>
                <Button variant="contained" color="primary" onClick={onClick} disabled={!answer}>{t('question.next_question')}</Button>
            </div>
            <Backdrop style={{ zIndex: 9999, color: '#fff' }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Fragment>
    )
}

export default withRouter(Question)