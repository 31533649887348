import React from 'react'

import {
    Paper,
    Typography,
    Grid,
    Button,
    CircularProgress,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

import { Close } from './ActionIcon'

const Panel = ({ title, button, status, onClose, onSubmit, children }) => {
    return (
        <Paper style={{ margin: '1rem 0', padding: '1rem' }}>
            <Grid container justify="space-between" alignItems="center">
                <Grid item>
                    <Typography color="primary">{title}</Typography>
                </Grid>
                {!!onClose &&
                    <Grid item>
                        <Close action={onClose} />
                    </Grid>
                }
            </Grid>
            <div style={{ marginTop: '1rem' }}>
                {children}
            </div>
            <div style={{ textAlign: 'center', marginTop: '1rem' }}>
                <Button disabled={status === 'loading'} variant="contained" color="primary" onClick={onSubmit} size="small" startIcon={<AddIcon />}>{button}</Button>
            </div>
            {status === 'loading' && <div style={{ textAlign: 'center', marginTop: '1rem' }}><CircularProgress size={30} /></div>}
            {status !== null && <Typography style={{ textAlign: 'center', marginTop: '1rem' }}>{status}</Typography>}
        </Paper>
    )
}

export default Panel