import React, { useState, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { useStoreState } from 'easy-peasy'
import { useTranslation } from 'react-i18next'

import {
    Typography,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
    FormControlLabel,
    Checkbox,
    Button,
} from '@material-ui/core'
import TimerIcon from '@material-ui/icons/Timer'
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople'
import RestoreIcon from '@material-ui/icons/Restore'
import Forward10Icon from '@material-ui/icons/Forward10'
import SecurityIcon from '@material-ui/icons/Security'

const instructions = [
    { icon: <EmojiPeopleIcon />, key: "text1" },
    { icon: <SecurityIcon />, key: "text2" },
    { icon: <TimerIcon />, key: "text3" },
    { icon: <RestoreIcon />, key: "text4" },
    { icon: <Forward10Icon />, key: "text5" },
]

const Start = ({
    history,
}) => {
    const { t } = useTranslation()
    const state = useStoreState(state => state.user)
    const [checked, setChecked] = useState(false)

    const onChange = () => setChecked(!checked)

    // not logged
    if (!state.isLogged) history.push('/token')

    return (
        <Fragment>
            <Typography component="h1" variant="h4" align="center" color="textPrimary" gutterBottom>
                {t('instructions.title')}
            </Typography>
            <List component="ul">
                {instructions.map((instr, i) =>
                    <ListItem key={i}>
                        <ListItemIcon>{instr.icon}</ListItemIcon>
                        <ListItemText>{t(instr.key)}</ListItemText>
                    </ListItem>
                )}
            </List>
            <Divider />
            <div style={{ textAlign: 'center', marginTop: '1rem' }}>
                <FormControlLabel
                    control={<Checkbox checked={checked} onChange={onChange} name="instructions" />}
                    label="J'ai lu les instructions et suis prêt à commencer le test"
                />
            </div>
            <div style={{ textAlign: 'center', marginTop: '1rem' }}>
                <Button variant="contained" align="center" color="primary" href="select" disabled={!checked}>Commencer le test</Button>
            </div>
        </Fragment>
    )
}

export default withRouter(Start)