import React from 'react'
import { useTranslation } from 'react-i18next'

import {
    Button,
    Grid,
    Typography,
    Container
} from '@material-ui/core';

const Home = () => {
    const { t } = useTranslation()

    return (
        <div style={{ padding: '1rem 0' }}>
            <Container maxWidth="sm">
                <Typography component="h1" variant="h2" align="center" gutterBottom>
                    QCX<sup style={{ fontSize: '1.8rem' }}>TM</sup>
                </Typography>
                <Typography align="center" paragraph>
                    {t("home.welcome")}
                </Typography>
                <div style={{ marginTop: '2rem' }}>
                    <Grid container spacing={2} justify="center">
                        <Grid item>
                            <Button variant="contained" color="primary" href="login">{t("home.button")}</Button>
                        </Grid>
                        {/* <Grid item>
                            <Button variant="outlined" color="primary" href="mailto:xroegiers@yahoo.fr?subject=Demande d'un compte">Demander un compte</Button>
                        </Grid> */}
                    </Grid>
                </div>
            </Container>
        </div>
    )
}

export default Home