import React, { useState, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { useStoreState, useStoreActions } from 'easy-peasy'

import {
    Typography,
    TextField,
    Button
} from '@material-ui/core'

const Login = ({
    history,
}) => {
    const isLogged = useStoreState(state => state.auth.isLogged)
    const actions = useStoreActions(actions => actions.auth)

    const [login, setValues] = useState({ email: '', password: '' })
    const [status, setStatus] = useState(null)

    const onChange = (e) => setValues({ ...login, [e.target.id]: e.target.value })
    const onClick = () => {
        actions.logIn(login)
            .catch(error => {
                switch (error.code) {
                    case 'auth/too-many-requests':
                        setStatus('Trop de tentatives de connexion infructueuses. Veuillez réessayer plus tard.');
                        break;
                    case 'auth/wrong-password':
                        setStatus('L\'adresse électronique ou le mot de passe n\'est pas valide.');
                        break;
                    default:
                        setStatus('Impossible de se connecter. Veuillez réessayer plus tard ou contacter info@qcx.be.');
                }
            })
    }

    if (isLogged) history.push('/my/dashboard')

    return (
        <Fragment>
            <Typography style={{ textAlign: 'center' }}>Entrez votre email et mot de passe :</Typography>
            <form noValidate autoComplete="off" style={{ marginTop: '1rem' }}>
                <TextField id="email" label="Email" value={login.email} variant="outlined" fullWidth onChange={onChange} autoComplete="email" />
                <TextField id="password" label="Mot de passe" value={login.password} type="password" variant="outlined" fullWidth onChange={onChange} style={{ marginTop: '1rem' }} autoComplete="current-password" />
            </form>
            <div style={{ textAlign: 'center', marginTop: '1rem' }}>
                <Button variant="contained" color="primary" onClick={onClick}>Se connecter</Button>
            </div>
            {status !== null && <Typography style={{ textAlign: 'center', marginTop: '1rem' }}>{status}</Typography>}
        </Fragment>
    )
}

export default withRouter(Login)