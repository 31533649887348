import * as firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/auth'
import 'firebase/storage'

const config = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE
}

if (!firebase.apps.length) {
    firebase.initializeApp(config)
}

// firestore
const db = firebase.firestore()

// const settings = { timestampsInSnapshots: true };
// db.settings(settings);

const { auth } = firebase

const storage = firebase.storage()

if (process.env.NODE_ENV === "development") {
    // firestore
    db.settings({
        host: "localhost:8080",
        ssl: false
    })
    // functions
    firebase.functions().useFunctionsEmulator('http://localhost:5001')
}

export { db, auth, storage }
