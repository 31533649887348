import { action, thunk, computed } from 'easy-peasy'

const state = {
    list: [],
    length: computed(state => state.list.length),
}

const actions = {
    setList: action((state, list) => {
        state.list = list
    }),
}

const thunks = {
    // admin
    listAll: thunk((actions, _, helpers) => {
        const { injections: { db } } = helpers

        const unsubscribe = db.collection('questionnaires')
            .onSnapshot(snap => {
                var docs = []
                snap.forEach(doc => docs.push({ ...doc.data(), id: doc.id }))
                actions.setList(docs)
            }, error => console.error(error))

        return unsubscribe
    }),

    // manager
    listAsOrganisation: thunk((actions, payload, helpers) => {
        const { injections: { db } } = helpers
        const organisationId = payload

        const unsubscribe = db.collection('questionnaires')
            .where('organisations', 'array-contains', organisationId)
            .onSnapshot(snap => {
                var docs = []
                snap.forEach(doc => docs.push({ ...doc.data(), id: doc.id }))
                actions.setList(docs)
            }, error => console.error(error))

        return unsubscribe
    }),

    // admin
    create: thunk((_, payload, helpers) => {
        const { injections: { db } } = helpers
        const { name, questions } = payload

        if (name.length < 3) return Promise.reject('name_too_short')

        const regex = /^Q[0-9a-zA-Z]{3,5}(,Q[0-9a-zA-Z]{3,5})*$/g;
        if (!regex.test(questions)) return Promise.reject('questions_wrong_format')

        const questionsArr = questions.split(',')

        return db.collection('questionnaires').add({ name: name, questions: questionsArr })
    }),

    // admin
    delete: thunk((_, payload, helpers) => {
        const { injections: { db } } = helpers
        const questionnaireId = payload

        return db.collection('questionnaires')
            .doc(questionnaireId)
            .delete()
    }),
}

export default {
    ...state,
    ...actions,
    ...thunks,
}
