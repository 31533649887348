import React, { Fragment } from 'react'

import ListQuestion from './ListQuestion'
import LoadQuestion from './LoadQuestion'

const QuestionIndex = () => {
    return (
        <Fragment>
            <LoadQuestion />
            <ListQuestion />
        </Fragment>
    )
}

export default QuestionIndex