import { action, thunk, computed } from 'easy-peasy'
import * as firebase from 'firebase/app'
import 'firebase/functions'

const retrieveFunction = (name) => {
    let functions = null
    if (process.env.NODE_ENV === "development") {
        functions = firebase.app().functions()
    } else {
        functions = firebase.app().functions('europe-west1')
    }
    return functions.httpsCallable(name)
}

const state = {
    list: [],
    length: computed(state => state.list.length),
}

const actions = {
    setList: action((state, list) => {
        state.list = list
    }),
}

const thunks = {
    // admin
    listAll: thunk((actions, _, helpers) => {
        const { injections: { db } } = helpers

        const unsubscribe = db.collection(`users`)
            .orderBy('email', 'asc')
            .onSnapshot(snap => {
                var docs = []
                snap.forEach(doc => docs.push({ ...doc.data(), id: doc.id }))
                actions.setList(docs)
            }, error => console.error(error))

        return unsubscribe
    }),

    // manager
    createOne: thunk((_, payload) => {
        const createOne = retrieveFunction('createOne')
        return createOne(payload)
    }),

    // manager
    createMultiple: thunk((_, payload) => {
        const createMultiple = retrieveFunction('createMultiple')
        return createMultiple(payload)
    }),

    // admin
    deleteUser: thunk((_, payload) => {
        const deleteUser = retrieveFunction('deleteUser')
        return deleteUser(payload)
    }),
}

export default {
    ...state,
    ...actions,
    ...thunks,
}
