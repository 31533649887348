import { action, thunk, computed } from "easy-peasy";
import * as firebase from "firebase/app";
import "firebase/functions";

const retrieveFunction = (name) => {
  let functions = null;
  if (process.env.NODE_ENV === "development") {
    functions = firebase.app().functions();
  } else {
    functions = firebase.app().functions("europe-west1");
  }
  return functions.httpsCallable(name);
};

const state = {
  // list
  list: [],
  length: computed((state) => state.list.length),

  listAssigned: computed((state) => state.list.filter((e) => e.status !== "completed")),
  areAssigned: computed((state) => state.list.filter((e) => e.status !== "completed").length),

  listCompleted: computed((state) => state.list.filter((e) => e.status === "completed")),
  areCompleted: computed((state) => state.list.filter((e) => e.status === "completed").length),

  // current test
  currentTest: {},
  currentQuestion: {},
  stopTest: null,

  questionsCount: computed((state) => state.currentTest?.answers?.length + 1 || 0),
  questionsTotal: computed((state) => state.currentTest?.questionnaire?.questions?.length || 0),

  isLastQuestion: computed((state) => state.questionsCount === state.questionsTotal),
  isTestCompleted: computed(
    (state) => state.currentTest.answers?.length >= state.currentTest.questionnaire?.questions.length || false
  ),
};

const actions = {
  setList: action((state, list) => {
    state.list = list;
  }),

  setCurrentTest: action((state, test) => {
    state.currentTest = test;
  }),

  setStopTest: action((state, stopTest) => {
    state.stopTest = stopTest;
  }),

  setCurrentQuestion: action((state, question) => {
    state.currentQuestion = question;
  }),
};

const thunks = {
  // admin
  listAll: thunk((actions, _, helpers) => {
    const {
      injections: { db },
    } = helpers;

    const unsubscribe = db
      .collection("tests")
      // .orderBy('created', 'asc')
      .onSnapshot(
        (snap) => {
          var docs = [];
          snap.forEach((doc) => docs.push({ ...doc.data(), id: doc.id }));
          actions.setList(docs);
        },
        (error) => console.error(error)
      );

    return unsubscribe;
  }),

  // manager
  listAsManager: thunk((actions, payload, helpers) => {
    const {
      injections: { db },
    } = helpers;
    const organisationId = payload;

    const unsubscribe = db
      .collection("tests")
      .where("organisation.id", "==", organisationId)
      // .orderBy('created', 'asc')
      .onSnapshot(
        (snap) => {
          var docs = [];
          snap.forEach((doc) => docs.push({ ...doc.data(), id: doc.id }));
          actions.setList(docs);
        },
        (error) => console.error(error)
      );

    return unsubscribe;
  }),

  // user
  listAsUser: thunk((actions, _, helpers) => {
    const {
      injections: { db },
      getStoreState,
    } = helpers;
    const userId = getStoreState().auth.user.uid;

    const unsubscribe = db
      .collection("tests")
      .where("user.id", "==", userId)
      // .orderBy('created', 'asc')
      .onSnapshot(
        (snap) => {
          var docs = [];
          snap.forEach((doc) => docs.push({ ...doc.data(), id: doc.id }));
          actions.setList(docs);
        },
        (error) => console.error(error)
      );

    return unsubscribe;
  }),

  // manager
  assign: thunk((_, payload, helpers) => {
    const {
      injections: { db },
    } = helpers;
    const { users, questionnaire, organisation } = payload;

    let promises = [];
    let emails = [];
    users.forEach((user) => {
      const promise = db.collection("tests").add({
        questionnaire: {
          id: questionnaire.id,
          name: questionnaire.name,
          questions: questionnaire.questions,
        },
        user: {
          id: user.id,
          email: user.email,
        },
        ...(organisation && {
          organisation: {
            id: organisation.id,
            name: organisation.name,
          },
        }),
        answers: [],
        status: "ready",
        created: firebase.firestore.FieldValue.serverTimestamp(),
        updated: firebase.firestore.FieldValue.serverTimestamp(),
      });

      promises.push(promise);
      emails.push(user.email);
    });

    return Promise.all(promises).then(() => {
      const mailingNewTest2 = retrieveFunction("mailingNewTest2");
      return mailingNewTest2(emails);
    });
  }),

  // admin
  reset: thunk((_, payload, helpers) => {
    const {
      injections: { db },
    } = helpers;
    const testId = payload;

    return db
      .collection("tests")
      .doc(testId)
      .set(
        {
          answers: [],
          status: "ready",
          resetCount: firebase.firestore.FieldValue.increment(1),
          updated: firebase.firestore.FieldValue.serverTimestamp(),
        },
        { merge: true }
      );
  }),

  // admin
  delete: thunk((_, payload, helpers) => {
    const {
      injections: { db },
    } = helpers;
    const testId = payload;

    return db.collection("tests").doc(testId).delete();
  }),

  /*
   * TEST EXECUTION
   */
  selectCurrentTest: thunk((actions, payload, helpers) => {
    // const { getState } = helpers
    // const testId = payload

    // const test = getState().tests.find(e => e.id === testId)
    // if (test) {
    //     actions.setCurrentTest(test)
    //     return Promise.resolve()
    // } else {
    //     return Promise.reject()
    // }

    const { testId, length, organisation } = payload;
    actions.setCurrentTest({ id: testId, length, organisation });
    return Promise.resolve();
  }),

  startTest: thunk((actions, payload, helpers) => {
    const {
      injections: { db },
      getState,
    } = helpers;
    const testId = getState().currentTest.id;

    const stopTest = db
      .collection("tests")
      .doc(testId)
      .onSnapshot(
        (doc) => actions.setCurrentTest({ ...doc.data(), id: doc.id }),
        (error) => console.error(error)
      );

    actions.setStopTest(stopTest);
    return Promise.resolve();
  }),

  loadQuestion: thunk((actions, _, helpers) => {
    const {
      injections: { db },
      getState,
    } = helpers;

    if (getState().isTestCompleted) {
      return Promise.reject("test_completed");
    }

    try {
      var index = getState().currentTest.answers.length;
      var questionId = getState().currentTest.questionnaire.questions[index];
    } catch (e) {
      return Promise.reject("not_loaded_yet");
    }

    return db
      .collection("questions")
      .doc(questionId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          actions.setCurrentQuestion({ ...doc.data(), id: doc.id });
          return Promise.resolve();
        } else {
          return Promise.reject("question_not_found");
        }
      })
      .catch((error) => {
        console.error(error);
        return Promise.reject("internal_server_error");
      });
  }),

  saveAnswer: thunk((actions, payload, helpers) => {
    const {
      injections: { db },
      getState,
    } = helpers;
    const answerId = payload;

    return db
      .collection("tests")
      .doc(getState().currentTest.id)
      .set(
        {
          answers: firebase.firestore.FieldValue.arrayUnion(answerId),
          updated: firebase.firestore.FieldValue.serverTimestamp(),
          status: "ongoing",
        },
        { merge: true }
      )
      .catch((error) => console.error(error));
  }),

  submitTest: thunk((actions, payload, helpers) => {
    const {
      injections: { db },
      getState,
      getStoreState,
    } = helpers;
    const { age, sex } = payload;

    getState().stopTest();

    return db
      .collection("tests")
      .doc(getState().currentTest.id)
      .set(
        {
          user: {
            email: getStoreState().auth.user.email,
            age: age,
            sex: sex,
          },
          status: "completed",
        },
        { merge: true }
      )
      .catch((error) => console.error(error));
  }),
};

export default {
  ...state,
  ...actions,
  ...thunks,
};
