import React, { Fragment, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useStoreState, useStoreActions } from 'easy-peasy'

import {
    Button,
    Grid,
    Typography,
    Paper,
    Divider,
} from '@material-ui/core'
import PeopleIcon from '@material-ui/icons/People'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import PostAddIcon from '@material-ui/icons/PostAdd'
import PageviewIcon from '@material-ui/icons/Pageview'
import BusinessIcon from '@material-ui/icons/Business'

import WhoAmI from '../shared/WhoAmI'

const Dashboard = ({ history }) => {
    const actions = useStoreActions(actions => actions)
    const state = useStoreState(state => state)

    useEffect(() => {
        actions.user.listAll()
        actions.organisation.listAll()
        actions.question.listAll()
        actions.questionnaire.listAll()
        actions.test.listAll()
    }, [actions])

    return (
        <Fragment>

            <Grid container spacing={3}>

                <WhoAmI />

                <Grid item xs={12} sm={6} md={4}>
                    <Paper style={{ padding: '2rem', textAlign: 'center' }}>
                        <Typography variant="h5">Utilisateurs</Typography>
                        <Divider />
                        <Typography variant="h1">{state.user.length}</Typography>
                        <Button size="small" variant="outlined" style={{ marginTop: '1rem' }} color="primary" onClick={() => history.push('/admin/user')} startIcon={<PeopleIcon />}>Gestion</Button>
                    </Paper>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <Paper style={{ padding: '2rem', textAlign: 'center' }}>
                        <Typography variant="h5">Organisations</Typography>
                        <Divider />
                        <Typography variant="h1">{state.organisation.length}</Typography>
                        <Button size="small" variant="outlined" style={{ marginTop: '1rem' }} color="primary" onClick={() => history.push('/admin/organisation')} startIcon={<BusinessIcon />}>Gestion</Button>
                    </Paper>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <Paper style={{ padding: '2rem', textAlign: 'center' }}>
                        <Typography variant="h5">Questions</Typography>
                        <Divider />
                        <Typography variant="h1">{state.question.length}</Typography>
                        <Button size="small" variant="outlined" style={{ marginTop: '1rem' }} color="primary" onClick={() => history.push('/admin/question')} startIcon={<CloudUploadIcon />}>Gestion</Button>
                    </Paper>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <Paper style={{ padding: '2rem', textAlign: 'center' }}>
                        <Typography variant="h5">Questionnaires</Typography>
                        <Divider />
                        <Typography variant="h1">{state.questionnaire.length}</Typography>
                        <Button size="small" variant="outlined" style={{ marginTop: '1rem' }} color="primary" onClick={() => history.push('/admin/questionnaire')} startIcon={<PostAddIcon />}>Gestion</Button>
                    </Paper>
                </Grid>

                <Grid item xs={12} sm={12} md={8}>
                    <Paper style={{ padding: '2rem' }}>
                        <Grid container spacing={5}>
                            <Grid item xs={12} sm={6} style={{ textAlign: 'center' }}>
                                <Typography variant="h5">Tests en cours</Typography>
                                <Divider />
                                <Typography variant="h1">{state.test.areAssigned}</Typography>
                                <Button disabled size="small" variant="outlined" style={{ marginTop: '1rem' }} color="primary" onClick={() => history.push('/admin/test/assign')} startIcon={<AssignmentIndIcon />}>Assigner un test</Button>
                            </Grid>
                            <Grid item xs={12} sm={6} style={{ textAlign: 'center' }}>
                                <Typography variant="h5">Tests terminés</Typography>
                                <Divider />
                                <Typography variant="h1">{state.test.areCompleted}</Typography>
                                <Button size="small" variant="outlined" style={{ marginTop: '1rem' }} color="primary" onClick={() => history.push('/admin/test/results')} startIcon={<PageviewIcon />}>Gestion</Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

            </Grid>

        </Fragment>
    )
}

export default withRouter(Dashboard)