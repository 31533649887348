import { createMuiTheme } from '@material-ui/core/styles'

// Create a theme instance.
const theme = createMuiTheme({
    // palette: {
    //     primary: {
    //         light: '#e3dc5b',
    //         main: '#ccc440',
    //         dark: '#b8af23',
    //         contrastText: '#fff',
    //     },
    //     secondary: {
    //         main: '#19857b',
    //     },
    //     light: {
    //         main: '#bbdefb',
    //     },
    //     background: {
    //         default: '#fff',
    //     },
    // },
    // typography: {
    //     fontSize: 12,
    // },
})

export default theme