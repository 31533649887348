import React, { useState } from 'react'
import { useStoreState } from 'easy-peasy'

import {
    FormControl,
    Select,
    InputLabel,
    MenuItem,
} from '@material-ui/core'

const SelectQuestionnaire = ({ questionnaireId, onChange, ...rest }) => {
    const questionnaires = useStoreState(state => state.questionnaire.list)
    const [index, setIndex] = useState(-1)

    const onSelect = (e) => {
        setIndex(e.target.value)
        onChange(questionnaires[e.target.value])
    }

    return (
        <FormControl variant="outlined" size="small" fullWidth {...rest}>
            <InputLabel>Questionnaire</InputLabel>
            <Select
                name="questionnaireId"
                value={index}
                onChange={onSelect}
                label="Questionnaire"
            >
                <MenuItem value={-1} disabled>Choisir un questionnaire :</MenuItem>
                {questionnaires?.map((questionnaire, i) => <MenuItem key={i} value={i}>{questionnaire.name}</MenuItem>)}
            </Select>
        </FormControl>
    )
}

export default SelectQuestionnaire