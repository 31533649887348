import React, { Fragment } from 'react'

import CreateUser from './CreateUser'
import ListUser from './ListUser'

const UserIndex = () => {
    return (
        <Fragment>
            <CreateUser />
            <ListUser />
        </Fragment >
    )
}

export default UserIndex