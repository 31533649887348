import React, { useState, Fragment } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'

import {
    Button,
    TextareaAutosize,
    Collapse,
    Typography,
} from '@material-ui/core'
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd'

import Panel from '../../shared/Panel'

const AddMembers = () => {
    const organisation = useStoreActions(actions => actions.organisation)
    const state = useStoreState(state => state.organisation)

    const [value, setValue] = useState('')
    const [status, setStatus] = useState(null)
    const [open, setOpen] = useState(false)

    const onChange = (e) => setValue(e.target.value)

    const onClose = () => {
        setOpen(false)
        setValue('')
        setStatus(null)
    }

    const onCreate = () => {
        const validEmails = /^[^\s@]+@[^\s@]+\.[^,\s@]+(,[^\s@]+@[^\s@]+\.[^,\s@]+)*$/.test(value)

        if (!validEmails) setStatus('invalid_email_list')
        else {
            setStatus('loading')
            organisation.addMembers({ emails: value, organisationId: state.current.id })
                .then(result => setStatus(result.data))
                .catch(error => setStatus(error))
        }
    }

    return (
        <Fragment>
            <Collapse in={!open}>
                <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => setOpen(true)}
                    startIcon={<PlaylistAddIcon />}
                    style={{ margin: '1rem 0' }}
                >Ajouter des membres</Button>
            </Collapse>
            <Collapse in={open}>
                <Panel title="Ajout de membres :" button="Ajouter les membres" status={status} onClose={onClose} onSubmit={onCreate}>
                    <Typography style={{ marginBottom: '0.5rem' }}>Veuillez introduire une ou plusieurs adresses email, séparées par des virgules et sans espaces:</Typography>
                    <TextareaAutosize
                        rowsMin={5}
                        placeholder="alice@email.com[,bob@email.com,...]"
                        value={value}
                        style={{ width: '100%' }}
                        onChange={onChange}
                    />
                </Panel>
            </Collapse>
        </Fragment>
    )
}

export default AddMembers