import React, { useState, useEffect, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { useStoreState, useStoreActions } from "easy-peasy";
import { useTranslation } from "react-i18next";

import {
  Grid,
  Typography,
  Paper,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";

import WhoAmI from "../shared/WhoAmI";

const Dashboard = ({ history }) => {
  const { t } = useTranslation();
  const actions = useStoreActions((actions) => actions);
  const state = useStoreState((state) => state.test);

  const testSubmitted = history.location.state?.testSubmitted;

  const selectTest = (testId, length, organisation) => {
    actions.test
      .selectCurrentTest({ testId, length, organisation })
      .then(() => history.push("/test"))
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    actions.test.listAsUser();
  }, [actions]);

  return (
    <Grid container spacing={3}>
      <WhoAmI />

      {testSubmitted && (
        <Grid item xs={12}>
          <TestSubmitted />
        </Grid>
      )}

      <Grid item xs={12} sm={6} md={4}>
        <Paper style={{ padding: "2rem", textAlign: "center" }}>
          <Typography variant="h5">{t("dashboard.test_assigned", { count: state.areAssigned })}</Typography>
          <Divider />
          <Typography variant="h1" style={{ margin: "1rem 0" }}>
            {state.areAssigned}
          </Typography>
          {state.areAssigned > 0 && (
            <Fragment>
              <Divider style={{ margin: "1rem 0" }} />
              <List>
                {state.listAssigned.map((test, i) => (
                  <ListItem
                    button
                    key={i}
                    onClick={() => selectTest(test.id, test.questionnaire.questions.length, test.organisation.name)}
                  >
                    <ListItemIcon>
                      <ArrowForwardIosIcon />
                    </ListItemIcon>
                    <ListItemText primary={test.questionnaire.name} />
                  </ListItem>
                ))}
              </List>
            </Fragment>
          )}
        </Paper>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <Paper style={{ padding: "2rem", textAlign: "center" }}>
          <Typography variant="h5">{t("dashboard.test_completed", { count: state.areCompleted })}</Typography>
          <Divider />
          <Typography variant="h1" style={{ margin: "1rem 0" }}>
            {state.areCompleted}
          </Typography>
          {state.areCompleted > 0 && (
            <Fragment>
              <Divider style={{ margin: "1rem 0" }} />
              <List>
                {state.listCompleted.map((test, i) => (
                  <ListItem key={i}>
                    <ListItemIcon>
                      <AssignmentTurnedInIcon style={{ color: "#b6edb6" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={test.questionnaire.name}
                      secondary={new Date(test.updated.seconds * 1000).toLocaleDateString("fr-BE")}
                    />
                  </ListItem>
                ))}
              </List>
            </Fragment>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default withRouter(Dashboard);

const TestSubmitted = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);

  return (
    <Collapse in={open}>
      <Alert severity="success" onClose={() => setOpen(false)}>
        <AlertTitle>{t("dashboard.message_title")}</AlertTitle>
        <div>
          {t("dashboard.message_content")}{" "}
          <a href="https://www.qcx.be/" target="_blank" rel="noopener noreferrer">
            https://www.qcx.be/
          </a>
        </div>
      </Alert>
    </Collapse>
  );
};
