import React, { useState, useEffect } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'

import {
    FormControl,
    Select,
    InputLabel,
    MenuItem,
} from '@material-ui/core'

const SelectUser = ({ onChange, ...rest }) => {
    const userList = useStoreState(state => state.user.list)
    const user = useStoreActions(actions => actions.user)

    const [value, setValue] = useState('')
    const onSelect = (e) => {
        setValue(e.target.value)
        onChange(e)
    }

    useEffect(() => {
        user.listAll()
    }, [user])

    return (
        <FormControl variant="outlined" size="small" fullWidth {...rest}>
            <InputLabel>Utilisateur</InputLabel>
            <Select
                name="userId"
                value={value}
                onChange={onSelect}
                label="Utilisateur"
            >
                <MenuItem value="" disabled>Choisir un utilisateur :</MenuItem>
                {userList?.map((user, i) => <MenuItem key={i} value={user.id}>{user.email}</MenuItem>)}
            </Select>
        </FormControl>
    )
}

export default SelectUser