import React, { useState, Fragment, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useStoreState, useStoreActions } from 'easy-peasy'

import {
    Typography,
    Button
} from '@material-ui/core'

import QuestionText from './QuestionText'
import QuestionImage from './QuestionImage'

const Token = ({
    history,
}) => {
    const state = useStoreState(state => state.user)
    const actions = useStoreActions(actions => actions.user)

    const [question, setQuestion] = useState({})
    const [choice, setChoice] = useState(null)

    const onClick = async () => {
        actions.saveAnswer(choice)
        if (state.isLastQuestion) history.push('/end')
    }

    useEffect(() => {
        actions.nextQuestion()
    }, [actions,  state.test.answers])

    useEffect(() => {
        setQuestion(state.question)
    }, [state.question])

    if (!state.isLogged) history.push('/token')
    if (state.isLogged && state.isTestFinished) history.push('/end')
    if (!question.hasOwnProperty('answers')) return null

    return (
        <Fragment>
            <Typography component="h1" variant="h4" align="center" color="textPrimary" gutterBottom>
                Question {state.questionCount}/{state.testLength}
            </Typography>
            {question.type === 'text' && <QuestionText question={question} choice={choice} setChoice={setChoice} />}
            {question.type === 'image' && <QuestionImage question={question} choice={choice} setChoice={setChoice} />}
            <div style={{ textAlign: 'center', marginTop: '1rem' }}>
                <Button variant="contained" color="primary" onClick={onClick} disabled={!choice}>Prochaine question</Button>
            </div>
        </Fragment>
    )
}

export default withRouter(Token)