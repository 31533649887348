import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import Forward10Icon from "@material-ui/icons/Forward10";
import RestoreIcon from "@material-ui/icons/Restore";
import SecurityIcon from "@material-ui/icons/Security";
import TimerIcon from "@material-ui/icons/Timer";
import { useStoreState } from "easy-peasy";
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

const instructions = [
  { icon: <EmojiPeopleIcon />, text: "info1" },
  { icon: <TimerIcon />, text: "info2", minutes: true },
  { icon: <RestoreIcon />, text: "info3" },
  { icon: <Forward10Icon />, text: "info4" },
  { icon: <SecurityIcon />, text: "info5" },
];

const Instructions = ({ history }) => {
  const { t } = useTranslation();
  const state = useStoreState((state) => state.test);
  const minutes = Math.max(Math.round(state.currentTest.length / 3 / 5) * 5, 5);

  const [checked, setChecked] = useState(false);
  const onChange = () => setChecked(!checked);

  return (
    <Fragment>
      <Typography component="h1" variant="h4" align="center" color="textPrimary" gutterBottom>
        {t("instructions.title")}
      </Typography>
      {state.currentTest.organisation === "Elluga" ? (
        <div style={{ fontSize: "1rem" }}>
          <p>Bienvenue,</p>
          <p>
            Le Qcx est un questionnaire qui permet d’identifier votre style entrepreneurial et les compétences qui y
            sont associées. Cela vous éclairera sur votre mode de fonctionnement, vos forces et vos défis ainsi que vos
            pistes de développement en tant qu’entrepreneur potentiel ou aguerri.
          </p>
          <p>
            Efforcez-vous de répondre à chaque question de la manière la plus spontanée possible afin que vos choix de
            réponses reflètent au mieux votre manière habituelle de fonctionner et d’interagir en toutes circonstances.
            Il n’y a pas de bonne ou mauvaise réponse.
          </p>
          <p>Il est également important de répondre à toutes les questions.</p>
          <p>
            L’objectif du questionnaire est de vous cerner de manière holistique. Si certaines questions peuvent vous
            surprendre, surtout restez vous-même et répondez-y en toute sincérité sans vous y attarder outre mesure.
          </p>
          <p>
            Le temps de passation de ce questionnaire n’est pas limité. A titre indicatif, cela vous prendra environ
            20-25 minutes.
          </p>
        </div>
      ) : (
        <List component="ul">
          {instructions.map((instr, i) => (
            <ListItem key={i}>
              <ListItemIcon>{instr.icon}</ListItemIcon>
              <ListItemText>{t(`instructions.${instr.text}`, { minutes: minutes })}</ListItemText>
              {/* <ListItemText>{instr.text.replace('%%MINUTES%%', Math.max(Math.round((state.currentTest.length / 3) / 5) * 5, 5))}</ListItemText> */}
            </ListItem>
          ))}
        </List>
      )}
      <Divider />
      <div style={{ textAlign: "center", marginTop: "1rem" }}>
        <FormControlLabel
          control={<Checkbox checked={checked} onChange={onChange} name="instructions" />}
          label={t("instructions.checkbox")}
        />
      </div>
      <div style={{ textAlign: "center", marginTop: "1rem" }}>
        <Button
          variant="contained"
          align="center"
          color="primary"
          onClick={() => history.push("/test/question")}
          disabled={!checked}
        >
          {t("instructions.button")}
        </Button>
      </div>
    </Fragment>
  );
};

export default withRouter(Instructions);
