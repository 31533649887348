import { createStore, persist } from 'easy-peasy'

import { auth, db } from '../firebase/config'
import authModel from './auth'
import testModel from './test'

const store = createStore(persist({
    auth: authModel,
    test: testModel,
}), {
    injections: { auth, db },
})

export default store