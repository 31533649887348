import React, { useState, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { useTranslation } from 'react-i18next'

import {
    Typography,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button
} from '@material-ui/core'

const Token = ({
    history,
}) => {
    const { t } = useTranslation()
    const state = useStoreState(state => state.test)
    const actions = useStoreActions(actions => actions.test)

    const [age, setAge] = useState(state.age || '')
    const [sex, setSex] = useState(state.sex || '')

    const onChangeAge = (e) => setAge(e.target.value)
    const onChangeSex = (e) => setSex(e.target.value)

    const onClick = async () => {
        if (!(Number.isInteger(+age) && +age > 4 && +age < 96)) return alert("Merci de rentrer un âge entre 5 et 95 ans")
        else if (!['m', 'f', 'x'].includes(sex)) return alert("Merci de choisir votre genre")

        actions.submitTest({ age, sex })
            .then(() => history.push('/my/dashboard', { testSubmitted: true }))
    }

    return (
        <Fragment>
            <Typography style={{ marginBottom: '2rem' }}>
                {t('submit.instructions')}
            </Typography>
            <form noValidate autoComplete="off">
                <Typography style={{ textAlign: 'center' }}>{t('submit.enter_age')}</Typography>
                <TextField style={{ marginTop: '1rem' }} id="age" label="Age" value={age} variant="outlined" fullWidth onChange={onChangeAge} />
                <Typography style={{ textAlign: 'center', marginTop: '1rem' }}>{t('submit.select_gender')}</Typography>
                <FormControl style={{ marginTop: '1rem' }} variant="outlined" fullWidth>
                    <InputLabel id="sex-label">Genre</InputLabel>
                    <Select
                        labelId="sex-label"
                        id="sex"
                        value={sex}
                        onChange={onChangeSex}
                        label="Genre"
                    >
                        <MenuItem value="" disabled>
                            <em>{t('submit.select_gender')}</em>
                        </MenuItem>
                        <MenuItem value="m">{t('submit.male')}</MenuItem>
                        <MenuItem value="f">{t('submit.female')}</MenuItem>
                        <MenuItem value="x">{t('submit.nosex')}</MenuItem>
                    </Select>
                </FormControl>
            </form>
            <div style={{ textAlign: 'center', marginTop: '1rem' }}>
                <Button variant="contained" color="primary" onClick={onClick}>{t('submit.button')}</Button>
            </div>
        </Fragment>
    )
}

export default withRouter(Token)