import React, { useState } from 'react'

import {
    IconButton,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'

// options = [{ icon, label function}, ...]

const TableOptions = ({ options, ...rest }) => {
    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const onClick = (callback) => () => {
        setAnchorEl(null)
        callback()
    }

    return (
        <div {...rest}>
            <IconButton size="small" onClick={handleClick} disabled={options.length === 0}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                elevation={1}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {options.map((option, i) => (
                    <MenuItem key={i} dense={true} onClick={onClick(option.action)}>
                        <ListItemIcon style={{ minWidth: '2.5rem' }}>
                            {option.icon}
                        </ListItemIcon>
                        <ListItemText primary={option.label} />
                    </MenuItem>
                ))}
            </Menu>
        </div>
    )
}

export default TableOptions