import React from 'react'
import { Switch, Route } from 'react-router-dom'

import CreateOrganization from './CreateOrganization'
import ListOrganization from './ListOrganization'
import ViewOrganization from './ViewOrganization'

const OrganizationIndex = () => {
    return (
        <Switch>
            <Route path="/admin/organisation/:orgId" component={ViewOrganization} />
            <Route>
                <CreateOrganization />
                <ListOrganization />
            </Route>
        </Switch>
    )
}

export default OrganizationIndex