import React, { useState, Fragment } from 'react'
import { useStoreActions } from 'easy-peasy'

import {
    Typography,
    TextareaAutosize,
    Button,
    Collapse,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

import Panel from '../../shared/Panel'

const LoadQuestions = () => {
    const question = useStoreActions(actions => actions.question)

    const initValue = []
    const [value, setValue] = useState(initValue)
    const [status, setStatus] = useState(null)
    const [open, setOpen] = useState(false)

    const onChange = (e) => setValue(e.target.value)
    const onClose = () => { setOpen(false); setValue(initValue); setStatus(null) }

    const onClick = () => {
        setStatus('loading')
        question.load(value)
            .then(() => setStatus('Les questions ont bien été chargées.'))
            .catch(error => setStatus(error))
    }

    return (
        <Fragment>
            <Collapse in={!open}>
                <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => setOpen(true)}
                    startIcon={<AddIcon />}
                    style={{ margin: '1rem 0' }}
                >Charger des questions</Button>
            </Collapse>
            <Collapse in={open}>
                <Panel title="Charger des questions :" button="Charger les questions" status={status} onClose={onClose} onSubmit={onClick}>
                    <Typography style={{ textAlign: 'center' }}>Entrez les questions en respecant le format en vigueur :</Typography>
                    <TextareaAutosize
                        rowsMin={20}
                        placeholder="[ { id: 'Qxxx', type: 'text', question: 'The question', answers: { Ixxx: 'The first answer', ... } }, { id: 'Qxxx', type: 'image', ..., answers: { Ixxx: 'The first image name', ... } } ]"
                        style={{ marginTop: '1rem', width: '100%' }}
                        onChange={onChange}
                    />
                </Panel>
            </Collapse>
        </Fragment>
    )
}

export default LoadQuestions