import React, { useEffect, Fragment } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { useStoreRehydrated, useStoreState, useStoreActions } from 'easy-peasy'
import './i18n'

import {
    CssBaseline,
    AppBar,
    Toolbar,
    Typography,
    Link,
    IconButton,
    Button,
    Select,
    MenuItem
} from '@material-ui/core'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import SettingsIcon from '@material-ui/icons/Settings'
import DashboardIcon from '@material-ui/icons/Dashboard'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import BusinessIcon from '@material-ui/icons/Business'

import { ThemeProvider } from '@material-ui/core/styles'
import theme from './theme'

import AdminRoute from './shared/AdminRoute'
import ManagerRoute from './shared/ManagerRoute'
import UserRoute from './shared/UserRoute'

import Home from './home'
import Admin from './admin'
import Manager from './manager'
import User from './user'
import Test from './test'
import i18n from './i18n'

const App = () => {
    const rehydrated = useStoreRehydrated()
    const actions = useStoreActions(actions => actions.auth)
    const auth = useStoreState(state => state.auth)

    async function switchLanguage(event) {
        if (event.target.value) {
            const lng = event.target.value
            // const lng = i18n.language === 'fr' ? 'nl' : 'fr'
            i18n.changeLanguage(lng).then(() => actions.setLang(lng))
        }
    }

    useEffect(() => {
        const unsubscribe = actions.onAuth()
        return () => unsubscribe()
    }, [actions])

    if (!rehydrated) return null

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <AppBar position="relative">
                <Toolbar>
                    <QuestionAnswerIcon style={{ marginRight: '1rem' }} />
                    <Typography variant="h6" color="inherit" style={{ flexGrow: 1 }}>
                        QCX
                    </Typography>
                    {auth.isAdmin &&
                        <IconButton href="/admin" style={{ color: 'white' }}>
                            <SettingsIcon />
                        </IconButton>
                    }
                    {(auth.isAdmin || auth.isManager) &&
                        <IconButton href="/manager" style={{ color: 'white' }}>
                            <BusinessIcon />
                        </IconButton>
                    }
                    {auth.isLogged &&
                        <Fragment>
                            <IconButton href="/my/dashboard" style={{ color: 'white' }}>
                                <DashboardIcon />
                            </IconButton>
                            <IconButton href="/logout" style={{ color: 'white' }}>
                                <ExitToAppIcon />
                            </IconButton>
                        </Fragment>
                    }
                    <Select value={auth.lang} style={{ color: 'white' }} onClick={switchLanguage}>
                        <MenuItem value="fr">FR</MenuItem>
                        <MenuItem value="en">EN</MenuItem>
                        <MenuItem value="nl">NL</MenuItem>
                        <MenuItem value="ru">RU</MenuItem>
                    </Select>
                </Toolbar>
            </AppBar>
            <main style={{ padding: '2rem' }}>
                <BrowserRouter>
                    <Switch>
                        <AdminRoute path="/admin" component={Admin} />
                        <ManagerRoute path="/manager" component={Manager} />
                        <UserRoute path="/my" component={User} />
                        <UserRoute path="/test" component={Test} />
                        <Route component={Home} />
                    </Switch>
                </BrowserRouter>
            </main>
            <footer style={{ padding: '6rem' }}>
                <Copyright />
            </footer>
        </ThemeProvider>
    )
}

export default App

const Copyright = () => {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://qcx.be/">
                QCX
            </Link>{' '}
            {new Date().getFullYear()}
        </Typography>
    );
}