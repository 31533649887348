import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useStoreState } from 'easy-peasy'

const UserRoute = ({ component: Component, ...rest }) => {
    const auth = useStoreState(state => state.auth)

    return (
        <Route {...rest} render={props => (
            auth.isLogged ?
                <Component {...props} />
                : <Redirect to="/login" />
        )} />
    )
}

export default UserRoute