import React, { Fragment } from 'react'

import ListQuestionnaire from './ListQuestionnaire'
import CreateQuestionnaire from './CreateQuestionnaire'

const QuestionnaireIndex = () => {
    return (
        <Fragment>
            <CreateQuestionnaire />
            <ListQuestionnaire />
        </Fragment>
    )
}

export default QuestionnaireIndex