import { action, thunk, computed } from 'easy-peasy'

const state = {
    list: [],
    length: computed(state => state.list.length),
}

const actions = {
    setList: action((state, list) => {
        state.list = list
    }),
}

const thunks = {
    listAll: thunk((actions, _, helpers) => {
        const { injections: { db } } = helpers

        const unsubscribe = db.collection('questions')
            .onSnapshot(snap => {
                var docs = []
                snap.forEach(doc => docs.push({ ...doc.data(), id: doc.id }))
                actions.setList(docs)
            }, error => console.error(error))

        return unsubscribe
    }),

    load: thunk((_, payload, helpers) => {
        const { injections: { db } } = helpers

        try {
            var questions = JSON.parse(payload)
            if (!Array.isArray(questions)) return Promise.reject('not_an_array')
        } catch (error) {
            return Promise.reject('parsing_error')
        }

        let promises = []
        for (const question of questions) {
            const { id, ...data } = question

            if (!id || id[0] !== 'Q') return Promise.reject('wrong_id_format')
            if (!data.hasOwnProperty('type') || !data.hasOwnProperty('question') || !data.hasOwnProperty('answers')) return Promise.reject('missing_question_data')

            promises.push(db.collection('questions').doc(id).set(data))
        }

        return Promise.all(promises)
    }),

    delete: thunk((_, payload, helpers) => {
        const { injections: { db } } = helpers
        const questionId = payload

        return db.collection('questions').doc(questionId).delete()
    }),
}

export default {
    ...state,
    ...actions,
    ...thunks,
}
