import React, { useEffect, useState } from 'react'
import { Switch, Route } from 'react-router-dom'

import store from '../store'
import organisationModel from '../store/organisation'
import questionnaireModel from '../store/questionnaire'

import Dashboard from './Dashboard'
import Organisation from './organisation'

const ManagerIndex = () => {
    const [storeLoaded, setLoaded] = useState(false)

    useEffect(() => {
        const { resolveRehydration: resolveOrganisation } = store.addModel('organisation', organisationModel)
        const { resolveRehydration: resolveQuestionnaire } = store.addModel('questionnaire', questionnaireModel)

        Promise.all([
            resolveOrganisation,
            resolveQuestionnaire]
        ).then(() => setLoaded(true))
    }, [])

    if (!storeLoaded) return null

    return (
        <Switch>
            <Route path="/manager/organisation" component={Organisation} />
            <Route component={Dashboard} />
        </Switch>
    )
}

export default ManagerIndex