import { useStoreActions } from 'easy-peasy'
import React, { useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'

import ViewOrganisation from './ViewOrganization'

const OrganizationIndex = () => {
    const organisation = useStoreActions(actions => actions.organisation)

    useEffect(() => {
        const unsubscribe = organisation.listAll()

        return () => unsubscribe()
    }, [organisation])

    return (
        <Switch>
            <Route path="/manager/organisation/:orgId" component={ViewOrganisation} />
            {/* <Route component={ListOrganisation} /> */}
        </Switch>
    )
}

export default OrganizationIndex