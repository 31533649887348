import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useStoreState, useStoreActions } from 'easy-peasy'

import {
    Button,
    Grid,
    Typography,
    Paper,
    Divider,
} from '@material-ui/core'
import PageviewIcon from '@material-ui/icons/Pageview'

import WhoAmI from '../shared/WhoAmI'

const Dashboard = ({ history }) => {
    const organisation = useStoreActions(actions => actions.organisation)
    const state = useStoreState(state => state.organisation)

    useEffect(() => {
        const unsubscribe = organisation.listAll()
        return () => unsubscribe()
    }, [organisation])

    return (
        <Grid container spacing={3}>

            <WhoAmI />

            {state.list.map((organisation, i) =>
                <Grid key={i} item xs={12} sm={6} md={4}>
                    <Paper style={{ textAlign: 'center' }}>
                        <Typography variant="h5"
                            style={{ padding: '0.5rem 0', color: 'white', backgroundColor: '#7388FF', borderTopLeftRadius: '4px', borderTopRightRadius: '4px' }}
                        >
                            {organisation.name}
                        </Typography>
                        <div style={{ padding: '2rem', textAlign: 'center' }}>
                            <Typography>Membres</Typography>
                            <Divider />
                            <Typography variant="h2">{organisation.members.length}</Typography>
                            <Button size="small"
                                variant="outlined"
                                style={{ marginTop: '1rem' }}
                                color="primary"
                                onClick={() => history.push(`/manager/organisation/${organisation.id}`)}
                                startIcon={<PageviewIcon />}
                            >Détails</Button>
                        </div>
                    </Paper>
                </Grid>
            )}
        </Grid>
    )
}

export default withRouter(Dashboard)