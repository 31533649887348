import React, { useState, Fragment, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useStoreState, useStoreActions } from 'easy-peasy'

import {
    Typography,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Chip,
    Button
} from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'

const Token = ({
    history,
}) => {
    const state = useStoreState(state => state.user)
    const actions = useStoreActions(actions => actions.user)

    const [tests, setTests] = useState([])
    const [choice, setChoice] = useState(null)

    const onClick = async () => {
        actions.selectTest(choice)
        history.push('/questionnaire')
    }

    useEffect(() => {
        actions.listQuestionnaires()
    }, [actions])

    useEffect(() => {
        console.log(state.tests)
        setTests(state.tests)
    }, [state.tests])

    // not logged
    if (!state.isLogged) history.push('/token')

    return (
        <Fragment>
            <Typography component="h1" variant="h4" align="center" color="textPrimary" gutterBottom>
                Choisissez le test :
            </Typography>
            <List component="ul">
                {tests.filter(test => test.answers.length < test.questionnaire.questions.length).map((test, i) =>
                    <ListItem key={i} button onClick={() => setChoice(test.id)} style={{ borderRadius: '5px', backgroundColor: `${choice === test.id ? '#b6edb6' : 'inherit'}` }}>
                        <ListItemIcon>{choice === test.id ? <CheckIcon /> : <CheckBoxOutlineBlankIcon />}</ListItemIcon>
                        <ListItemText>
                            {test.questionnaire.name}
                            {test.answers.length > 0 && <Chip size="small" color="secondary" label="En cours" style={{ marginLeft: '1rem' }} />}
                        </ListItemText>
                    </ListItem>
                )}
            </List>
            <div style={{ textAlign: 'center', marginTop: '1rem' }}>
                <Button variant="contained" color="primary" onClick={onClick} disabled={!choice}>Commencer le test</Button>
            </div>
        </Fragment>
    )
}

export default withRouter(Token)