import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useStoreActions } from 'easy-peasy'
// import { useStoreState, useStoreActions } from 'easy-peasy'

import Alert from '@material-ui/lab/Alert'

const End = ({ history }) => {
    const actions = useStoreActions(actions => actions.user)
    // const state = useStoreState(state => state.user)

    // if (!state.isTestFinished) history.push('/select')

    useEffect(() => {
        actions.submitTest()
    }, [actions])

    return (
        <Alert elevation={6} severity="success">
            Vous avez répondu à l'ensemble des questions.<br/><br/>
            Nous vous contacterons par email pour vous communiquer les résultats.
        </Alert>
    )
}

export default withRouter(End)