import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Home from './Home'
import LogIn from './LogIn'
import LogOut from './LogOut'

const Index = () => {
    return (
        <Switch>
            <Route exact path="/login" component={LogIn} />
            <Route exact path="/logout" component={LogOut} />
            <Route component={Home} />
        </Switch>
    )
}

export default Index
