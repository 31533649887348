import React, { Fragment } from 'react'

import {
    Typography,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'

const QuestionText = ({
    question, choice, setChoice,
}) => {
    return (
        <Fragment>
            <Typography>
                {question.question}
            </Typography>
            <List component="ul">
                {Object.keys(question.answers).sort().map((key, i) =>
                    <ListItem key={i} button onClick={() => setChoice(key)} style={{ borderRadius: '5px', backgroundColor: `${choice === key ? '#b6edb6' : 'inherit'}` }}>
                        <ListItemIcon>{choice === key ? <CheckIcon /> : <CheckBoxOutlineBlankIcon />}</ListItemIcon>
                        <ListItemText>{question.answers[key]}</ListItemText>
                    </ListItem>
                )}
            </List>
        </Fragment>
    )
}

export default QuestionText