import { action, thunk, thunkOn, computed } from 'easy-peasy'
import * as firebase from 'firebase/app'
import 'firebase/functions'

const retrieveFunction = (name) => {
    let functions = null
    if (process.env.NODE_ENV === "development") {
        functions = firebase.app().functions()
    } else {
        functions = firebase.app().functions('europe-west1')
    }
    return functions.httpsCallable(name)
}

const state = {
    list: [],
    current: null,
    members: [],
    tests: [],
    length: computed(state => state.list.length),
}

const actions = {
    setList: action((state, list) => {
        state.list = list
    }),

    setCurrent: action((state, organisationId) => {
        state.current = state.list.find(organisation => organisation.id === organisationId)
    }),

    setMembers: action((state, members) => {
        state.members = members
    }),

    setTests: action((state, tests) => {
        state.tests = tests
    }),
}

const thunks = {
    // admin & manager
    listAll: thunk((actions, _, helpers) => {
        const { injections: { db }, getState, getStoreState } = helpers
        const { user: { uid }, isAdmin } = getStoreState().auth

        var query = db.collection('organisations')

        if (!isAdmin) {
            query = query.where('managers', 'array-contains', uid)
        }

        const unsubscribe = query.orderBy('name', 'asc')
            .onSnapshot(snap => {
                var docs = []
                snap.forEach(doc => docs.push({ ...doc.data(), id: doc.id }))
                actions.setList(docs)
                if (getState().current?.id)
                    actions.setCurrent(getState().current?.id)
            }, error => console.error(error))

        return unsubscribe
    }),

    // admin
    create: thunk((_, payload, helpers) => {
        const { injections: { db } } = helpers
        const { name } = payload

        return db.collection('organisations').add({
            name: name,
            managers: [],
            members: [],
            created: firebase.firestore.FieldValue.serverTimestamp(),
        })
    }),

    // admin
    delete: thunk((_, payload, helpers) => {
        const { injections: { db } } = helpers
        const organisation = payload

        var batch = db.batch()

        const orgRef = db.collection('organisations').doc(organisation.id)
        batch.delete(orgRef)

        organisation.managers.forEach(managerId => {
            const userRef = db.collection('users').doc(managerId)
            batch.update(userRef, {
                managerOf: firebase.firestore.FieldValue.arrayRemove(organisation.id)
            })
        })

        organisation.members.forEach(memberId => {
            const userRef = db.collection('users').doc(memberId)
            batch.update(userRef, {
                memberOf: firebase.firestore.FieldValue.arrayRemove(organisation.id)
            })
        })

        return batch.commit()//.catch(error => console.error(error))
    }),

    // manager
    listMembers: thunkOn(
        // targetResolver:
        actions => actions.setCurrent,
        // handler:
        (actions, target, helpers) => {
            const { injections: { db } } = helpers
            const organisationId = target.payload

            const unsubscribe = db.collection('users')
                .where('memberOf', 'array-contains', organisationId)
                .orderBy('email', 'asc')
                .onSnapshot(snap => {
                    console.log(`Received query snapshot of size ${snap.size}`)
                    var docs = []
                    snap.forEach(doc => docs.push({ ...doc.data(), id: doc.id }))
                    actions.setMembers(docs)
                }, error => console.error(error))

            return unsubscribe
        }
    ),

    // manager
    addMembers: thunk((_, payload) => {
        const addMembers = retrieveFunction('addMembers')
        return addMembers(payload)
    }),

    // manager
    deleteMember: thunk((_, payload, helpers) => {
        const { injections: { db } } = helpers
        const { organisationId, memberId } = payload

        var batch = db.batch()

        const orgRef = db.collection('organisations').doc(organisationId)
        batch.update(orgRef, {
            members: firebase.firestore.FieldValue.arrayRemove(memberId)
        })

        const userRef = db.collection('users').doc(memberId)
        batch.update(userRef, {
            memberOf: firebase.firestore.FieldValue.arrayRemove(organisationId)
        })

        // TODO: delete test in status ready attached to user and organisation

        return batch.commit()//.catch(error => console.error(error))
    }),

    // manager
    addManager: thunk((_, payload, helpers) => {
        const { injections: { db } } = helpers
        const { organisationId, managerId } = payload

        var batch = db.batch()

        const orgRef = db.collection('organisations').doc(organisationId)
        batch.update(orgRef, {
            managers: firebase.firestore.FieldValue.arrayUnion(managerId)
        })

        const userRef = db.collection('users').doc(managerId)
        batch.update(userRef, {
            managerOf: firebase.firestore.FieldValue.arrayUnion(organisationId)
        })

        return batch.commit()//.catch(error => console.error(error))
    }),

    // manager
    deleteManager: thunk((_, payload, helpers) => {
        const { injections: { db } } = helpers
        const { organisationId, managerId } = payload

        var batch = db.batch()

        const orgRef = db.collection('organisations').doc(organisationId)
        batch.update(orgRef, {
            managers: firebase.firestore.FieldValue.arrayRemove(managerId)
        })

        const userRef = db.collection('users').doc(managerId)
        batch.update(userRef, {
            managerOf: firebase.firestore.FieldValue.arrayRemove(organisationId)
        })

        return batch.commit()//.catch(error => console.error(error))
    }),

    // manager
    listTests: thunkOn(
        // targetResolver:
        actions => actions.setCurrent,
        // handler:
        (actions, target, helpers) => {
            const { injections: { db } } = helpers
            const organisationId = target.payload

            const unsubscribe = db.collection('tests')
                .where('organisation.id', '==', organisationId)
                // .orderBy('created', 'desc')
                .onSnapshot(snap => {
                    console.log(`Received query snapshot of size ${snap.size}`)
                    var docs = []
                    snap.forEach(doc => docs.push({ ...doc.data(), id: doc.id }))
                    actions.setTests(docs)
                }, error => console.error(error))

            return unsubscribe
        }
    ),

    // admin
    addCredits: thunk((_, payload, helpers) => {
        const { injections: { db } } = helpers
        const { organisationId, questionnaire, nbCredits } = payload

        return db.collection('organisations').doc(organisationId).set({
            credits: {
                [questionnaire.id]: {
                    name: questionnaire.name,
                    used: firebase.firestore.FieldValue.increment(0),
                    left: firebase.firestore.FieldValue.increment(nbCredits)
                }
            }
        }, { merge: true })
    }),

    // admin
    removeQuestionnaire: thunk((_, payload, helpers) => {
        const { injections: { db } } = helpers
        const { organisationId, questionnaireId } = payload

        var batch = db.batch()

        const questRef = db.collection('questionnaires').doc(questionnaireId)
        batch.update(questRef, {
            organisations: firebase.firestore.FieldValue.arrayRemove(organisationId)
        })

        const orgRef = db.collection('organisations').doc(organisationId)
        batch.update(orgRef, {
            [`credits.${questionnaireId}`]: firebase.firestore.FieldValue.delete()
        })

        return batch.commit()//.catch(error => console.error(error))
    }),

    // manager
    assignQuestionnaire: thunk((_, payload) => {
        const assignQuestionnaire = retrieveFunction('assignQuestionnaire')
        return assignQuestionnaire(payload)
    }),
}

export default {
    ...state,
    ...actions,
    ...thunks,
}
