import React, { useEffect } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@material-ui/core'

import {
    // View, 
    Delete
} from '../../shared/ActionIcon'

const Organization = () => {
    const questionnaire = useStoreActions(actions => actions.questionnaire)
    const state = useStoreState(state => state.questionnaire)

    useEffect(() => {
        const unsubscribe = questionnaire.listAll()
        return () => unsubscribe()
    }, [questionnaire])

    return (
        <TableContainer style={{ margin: '1rem 0' }} component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Nom</TableCell>
                        <TableCell>#</TableCell>
                        <TableCell>Questions</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {state.list.map((q, i) => (
                        <TableRow key={i}>
                            <TableCell>{q.name}</TableCell>
                            <TableCell>{q.questions.length}</TableCell>
                            <TableCell>{q.questions.join(', ')}</TableCell>
                            <TableCell align="right">
                                {/* <View /> */}
                                <Delete action={() => questionnaire.delete(q.id)} disabled />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default Organization