import React from 'react'
import { Switch, Route } from 'react-router-dom'

import ListTests from './ListTests'
import AssignTest from './AssignTest'

const TestIndex = () => {
    return (
        <Switch>
            <Route path="/admin/test/assign" component={AssignTest} />
            <Route component={ListTests} />
        </Switch>
    )
}

export default TestIndex