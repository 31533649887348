import { action, thunk, computed } from 'easy-peasy'

const state = {
    user: null,
    profile: null,
    lang: 'fr',
    isAdmin: false,
    isManager: computed(state => Boolean(state.profile?.managerOf?.length)),
    isLogged: computed(state => state.user !== null),
}

const actions = {
    setUser: action((state, user) => {
        state.user = user
    }),

    setProfile: action((state, profile) => {
        state.profile = profile
    }),

    setLang: action((state, lang) => {
        state.lang = lang
    }),

    setAdmin: action((state, admin) => {
        state.isAdmin = admin
    }),

    clearAuth: action((state, _) => {
        state.user = null
        state.isAdmin = false
    }),
}

const thunks = {
    logIn: thunk((_, payload, helpers) => {
        const { injections: { auth } } = helpers
        const { email, password } = payload

        return auth().signInWithEmailAndPassword(email, password)
        // .catch(error => console.error(error))
    }),

    onAuth: thunk((actions, _, helpers) => {
        const { injections: { auth } } = helpers

        return auth().onAuthStateChanged(user => {
            if (user) {
                actions.setUser(user)
                actions.getProfile(user.uid)
                user.getIdTokenResult().then(result => actions.setAdmin(result.claims.admin))
            } else {
                actions.setUser(null)
                actions.setAdmin(false)
            }
        })
    }),

    getProfile: thunk((actions, payload, helpers) => {
        const { injections: { db } } = helpers
        const userId = payload

        db.collection('users').doc(userId).get().then(doc => {
            if (doc.exists) {
                actions.setProfile(doc.data())
            }
        })
    }),

    logOut: thunk((actions, __, helpers) => {
        const { injections: { auth } } = helpers

        auth().signOut()
            .then(() => actions.clearAuth())
            .catch(error => console.error(error))
    }),
}

export default {
    ...state,
    ...actions,
    ...thunks,
}
