import React from 'react'
import { withRouter, Switch, Route } from 'react-router-dom'
import { useStoreState } from 'easy-peasy'

import Instructions from './Instructions'
import Question from './Question'
import Submit from './Submit'

// import Dashboard from './Dashboard'
// import Info from './Info'

// import Select from './Select'
// import Questionnaire from './Questionnaire'
// import End from './End'

const TestIndex = ({ history }) => {
    const currentTest = useStoreState(state => state.test?.currentTest)

    if (!currentTest) history.push('/my/dashboard')

    return (
        <Switch>
            {/* <Route exact path="/start" component={Start} />
            <Route exact path="/select" component={Select} />
            <Route exact path="/questionnaire" component={Questionnaire} />
            <Route exact path="/end" component={End} /> */}
            <Route exact path="/test/submit" component={Submit} />
            <Route exact path="/test/question" component={Question} />
            <Route component={Instructions} />
        </Switch>
    )
}

export default withRouter(TestIndex)
