import React, { useState } from 'react'
import { useStoreState } from 'easy-peasy'

import {
    FormControl,
    Select,
    InputLabel,
    MenuItem,
} from '@material-ui/core'

const SelectOrganization = ({ onChange, noneOption, ...rest }) => {
    const organizations = useStoreState(state => state.admin.organizations)

    const [value, setValue] = useState(noneOption ? 'none' : '')
    const onSelect = (e) => {
        setValue(e.target.value)
        onChange(e)
    }

    return (
        <FormControl variant="outlined" size="small" fullWidth {...rest}>
            <InputLabel>Organisation</InputLabel>
            <Select
                name="orgId"
                value={value}
                onChange={onSelect}
                label="Organisation"
            >
                <MenuItem value="" disabled>Choisir une organisation :</MenuItem>
                {noneOption && <MenuItem value="none">– Aucune –</MenuItem>}
                {organizations?.map((organization, i) => <MenuItem key={i} value={organization.id}>{organization.name}</MenuItem>)}
            </Select>
        </FormControl>
    )
}

export default SelectOrganization