import React, { useState, useEffect } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    InputBase,
    Hidden,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import DeleteIcon from '@material-ui/icons/Delete'

import {
    Clear,
    MailFailure,
    MailSuccess
} from '../../shared/ActionIcon'

import TableOptions from '../../shared/TableOptions'

const ListUsers = () => {
    const user = useStoreActions(actions => actions.user)
    const organisation = useStoreActions(actions => actions.organisation)
    const state = useStoreState(state => state)

    const [filter, setFilter] = useState('')
    const [filteredUsers, setUsers] = useState(state.user.list)

    const remove = (userObj) => {
        if (window.confirm('Êtes-vous sur de vouloir supprimer cet utilisateur ?'))
            user.deleteUser(userObj)
    }

    const showOrgName = (orgIds) => {
        return orgIds?.map(orgId => state.organisation.list.find(e => e.id === orgId)?.name).join(', ')
    }

    useEffect(() => {
        if (state.user.list) setUsers(state.user.list.filter(e => e.email.includes(filter)))
    }, [state.user.list, filter])

    useEffect(() => {
        const unsubscribe = user.listAll()
        const unsubOrg = organisation.listAll()
        return () => { unsubscribe(); unsubOrg() }
    }, [user, organisation])

    return (
        <TableContainer style={{ margin: '1rem 0' }} component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell width="1px"></TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Organisation</TableCell>
                        <TableCell>
                            <Hidden xsDown><div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <div style={{ display: 'flex', alignItems: 'center', padding: '0 0.5rem', backgroundColor: '#f1f1f1', borderRadius: '5px' }}>
                                    <SearchIcon style={{ paddingRight: '0.5rem' }} />
                                    <InputBase
                                        placeholder="Recherche"
                                        value={filter}
                                        onChange={(e) => setFilter(e.target.value)}
                                    />
                                    {filter !== '' && <Clear tooltip="Effacer" action={() => setFilter('')} />}
                                </div>
                            </div>
                            </Hidden>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredUsers?.map((user, i) => (
                        <TableRow key={i} hover>
                            <TableCell>{user.sendMail ? <MailSuccess /> : <MailFailure />}</TableCell>
                            <TableCell>{user.email}</TableCell>
                            <TableCell>{showOrgName(user.memberOf)}</TableCell>
                            <TableCell align="right">
                                <TableOptions options={[
                                    { label: "Supprimer", icon: <DeleteIcon />, action: () => remove(user) },
                                ]} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer >
    )
}

export default ListUsers