import React, { useState, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { useStoreState, useStoreActions } from 'easy-peasy'

import {
    Typography,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button
} from '@material-ui/core'

const Token = ({
    history,
}) => {
    const state = useStoreState(state => state.user)
    const actions = useStoreActions(actions => actions.user)

    const [age, setAge] = useState(state.age || '')
    const [sex, setSex] = useState(state.sex || '')

    const onChangeAge = (e) => setAge(e.target.value)
    const onChangeSex = (e) => setSex(e.target.value)

    const onClick = async () => {
        if (!(Number.isInteger(+age) && +age > 0 && ['m','f','x'].includes(sex))) return alert("Merci de compléter correctement les deux champs")

        actions.updateInfo({ age, sex })
        // ok
        history.push('/start')
    }

    // not logged
    if (!state.isLogged) history.push('/token')

    return (
        <Fragment>
            <form noValidate autoComplete="off">
                <Typography style={{ textAlign: 'center' }}>Entrez votre age :</Typography>
                <TextField style={{ marginTop: '1rem' }} id="age" label="Age" value={age} variant="outlined" fullWidth onChange={onChangeAge} />
                <Typography style={{ textAlign: 'center', marginTop: '1rem' }}>Sélectionnez votre genre :</Typography>
                <FormControl style={{ marginTop: '1rem' }} variant="outlined" fullWidth>
                    <InputLabel id="sex-label">Sexe</InputLabel>
                    <Select
                        labelId="sex-label"
                        id="sex"
                        value={sex}
                        onChange={onChangeSex}
                        label="Genre"
                    >
                        <MenuItem value="" disabled>
                            <em>Choisissez votre genre</em>
                        </MenuItem>
                        <MenuItem value="m">Homme</MenuItem>
                        <MenuItem value="f">Femme</MenuItem>
                        <MenuItem value="x">Je me considère comme n'étant ni homme ni femme</MenuItem>
                    </Select>
                </FormControl>
            </form>
            <div style={{ textAlign: 'center', marginTop: '1rem' }}>
                <Button variant="contained" color="primary" onClick={onClick}>Continuer</Button>
            </div>
        </Fragment>
    )
}

export default withRouter(Token)