import React, { useState, Fragment } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'

import {
    Collapse,
    Typography,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
} from '@material-ui/core'

import Panel from '../../shared/Panel'

const AssignQuestionnaire = ({ user, close }) => {
    const organisation = useStoreActions(actions => actions.organisation)
    const state = useStoreState(state => state.organisation)

    const [value, setValue] = useState('')
    const [status, setStatus] = useState(null)

    const onChange = (e) => setValue(e.target.value)

    const onClose = () => {
        close()
        setValue('')
        setStatus(null)
    }

    const onSubmit = () => {
        setStatus('loading')
        organisation.assignQuestionnaire({ users: [user], organisation: state.current, questionnaireId: value })
            .then(() => setStatus('Le questionnaire a bien été assigné à l\'utilisateur'))
            .catch(error => setStatus(error.message))
    }

    return (
        <Fragment>
            <Collapse in={!!user}>
                <Panel title="Assigner un questionnaire :" button="Assigner le questionnaire" status={status} onClose={onClose} onSubmit={onSubmit}>
                    <Typography style={{ marginBottom: '0.5rem' }}>Veuillez choisir le questionnaire à assigner à l'utilisateur <b>{user.email}</b> :</Typography>
                    <FormControl variant="outlined" size="small" fullWidth style={{ marginTop: '1rem' }}>
                        <InputLabel>Questionnaire</InputLabel>
                        <Select
                            name="questionnaireId"
                            value={value}
                            onChange={onChange}
                            label="Questionnaire"
                        >
                            <MenuItem value="" disabled>Choisir un questionnaire :</MenuItem>
                            {state.current?.credits && Object.entries(state.current.credits).map(([key, value]) =>
                                <MenuItem key={key} value={key} disabled={value.left === 0}>{value.name} – {value.left} credits restants</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Panel>
            </Collapse>
        </Fragment>
    )
}

export default AssignQuestionnaire