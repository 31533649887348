import React, { useState, useEffect, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { useStoreState, useStoreActions } from 'easy-peasy'

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Button,
    Grid,
    Divider,
    TextareaAutosize,
    Collapse,
} from '@material-ui/core'
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd'

import { Back, View, Delete } from '../../shared/ActionIcon'
import Panel from '../../shared/Panel'

const Organization = ({ history, match }) => {
    const actions = useStoreActions(actions => actions.admin)
    const state = useStoreState(state => state.admin)
    const orgId = match.params.orgId

    const [organization, setOrganization] = useState(null)
    const [value, setValue] = useState('')
    const [status, setStatus] = useState(null)
    const [open, setOpen] = useState(false)

    const onChange = (e) => setValue(e.target.value)
    const onClose = () => { setOpen(false); setValue(''); setStatus(null) }

    const onCreate = () => {
        const validEmails = /^[^\s@]+@[^\s@]+\.[^,\s@]+(,[^\s@]+@[^\s@]+\.[^,\s@]+)*$/.test(value)

        if (!validEmails) setStatus('invalid_email_list')
        else {
            setStatus('loading')
            createUsers()
        }
    }

    const createUsers = () => {
        actions.createUsers({ emails: value, orgId: orgId })
            .then(status => setStatus(status))
            .catch(error => setStatus(error))
    }

    useEffect(() => {
        setOrganization(state.organizations.find(e => e.id === orgId))
    }, [actions, state, orgId])

    return (
        <Fragment>
            <Grid container alignItems="center">
                <Grid item style={{ padding: '0 1rem' }}>
                    <Back action={() => history.goBack()} />
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item style={{ padding: '0 1rem' }}>
                    <Typography component="span" color="primary">Organisation: {organization?.name}</Typography>
                </Grid>
                <Grid item style={{ padding: '0 1rem' }}>
                    <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => setOpen(true)}
                        startIcon={<PlaylistAddIcon />}
                        style={{ margin: '1rem 0' }}
                    >Créer plusieurs utilisateurs</Button>
                </Grid>
            </Grid>
            <Collapse in={open}>
                <Panel title="Ajout de plusieurs utilisateurs à l'organisation :" button="Créer les utilisateurs" status={status} onClose={onClose} onSubmit={onCreate}>
                    <TextareaAutosize
                        rowsMin={5}
                        placeholder="email@domain.com,email@domain.com,..."
                        style={{ width: '100%' }}
                        onChange={onChange}
                    />
                </Panel>
            </Collapse>
            <TableContainer style={{ margin: '1rem 0' }} component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Email</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.users.filter(e => organization?.members.includes(e.id)).map((member, i) => (
                            <TableRow key={i}>
                                <TableCell>{member.email}</TableCell>
                                <TableCell align="right">
                                    <View />
                                    <Delete action={() => actions.removeMember({ userId: member.id, orgId })} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Fragment>
    )
}

export default withRouter(Organization)