import React, { useState, Fragment } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@material-ui/core'
import AssignmentIcon from '@material-ui/icons/Assignment'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled'
import DeleteIcon from '@material-ui/icons/Delete'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'

import TableOptions from '../../shared/TableOptions'

import AddMembers from './AddMembers'
import AssignQuestionnaire from './AssignQuestionnaire'

const ViewOrganisation = () => {
    const organisation = useStoreActions(actions => actions.organisation)
    // const questionnaire = useStoreActions(actions => actions.questionnaire)
    const state = useStoreState(state => state.organisation)
    const auth = useStoreState(state => state.auth)

    const [user, setUser] = useState(false)

    const deleteMember = (member) => {
        if (window.confirm('Êtes-vous certain de vouloir supprimer ce membre ?'))
            organisation.deleteMember({ memberId: member.id, organisationId: state.current.id })
    }

    // useEffect(() => {
    //     const unsubscribe = questionnaire.listAsOrganisation(state.current?.id)
    //     return () => unsubscribe()
    // }, [questionnaire, state])

    return (
        <Fragment>
            <AddMembers />
            <AssignQuestionnaire user={user} close={() => setUser(false)} />
            <TableContainer style={{ margin: '1rem 0' }} component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Email</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.members.map((member, i) => (
                            <TableRow key={i} hover>
                                <TableCell>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                        {member.email} {member.managerOf?.includes(state.current?.id) && <SupervisorAccountIcon size="small" style={{ marginLeft: '0.5rem', color: '#e0e0e0' }} />}
                                    </div>
                                </TableCell>
                                <TableCell align="right">
                                    {/* <View /> */}
                                    {/* <Delete action={() => actions.removeMember({ userId: member.id, orgId })} /> */}
                                    <TableOptions options={[
                                        { label: "Assigner un questionnaire", icon: <AssignmentIcon />, action: () => setUser(member) },
                                        ...[auth.isAdmin
                                            ? (member.managerOf?.includes(state.current?.id)
                                                ? { label: "Destituer", icon: <PersonAddDisabledIcon />, action: () => organisation.deleteManager({ organisationId: state.current?.id, managerId: member.id }) }
                                                : { label: "Promouvoir", icon: <PersonAddIcon />, action: () => organisation.addManager({ organisationId: state.current?.id, managerId: member.id }) })
                                            : undefined],
                                        { label: "Supprimer", icon: <DeleteIcon />, action: () => deleteMember(member) },
                                    ].filter(e => e !== undefined)} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Fragment>
    )
}

export default ViewOrganisation