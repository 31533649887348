import React from 'react'

import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import EditIcon from '@material-ui/icons/Edit'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import DeleteIcon from '@material-ui/icons/Delete'
import CloseIcon from '@material-ui/icons/Close'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import ReplayIcon from '@material-ui/icons/Replay'
import ClearIcon from '@material-ui/icons/Clear'
import SearchIcon from '@material-ui/icons/Search'
import MailOutlineIcon from '@material-ui/icons/MailOutline';

const IconAction = ({ tooltip, icon, action = () => { }, color = 'primary', ...rest }) => {

    // if (!!action) {
    return (
        <Tooltip title={tooltip}>
            <IconButton size="small" onClick={action} color={color} {...rest}>
                {icon}
            </IconButton>
        </Tooltip>
    )
    // } else {
    //     return (
    //         <Tooltip title={tooltip}>
    //             <IconButton size="small" color={color} {...rest}>
    //                 {icon}
    //             </IconButton>
    //         </Tooltip>
    //     )
    // }
}

export const MailFailure = (props) => <IconAction tooltip="Erreur lors de l'envoi" icon={<MailOutlineIcon />} style={{ color: '#ffb6b3' }} {...props} />
export const MailSuccess = (props) => <IconAction tooltip="Mail envoyé" icon={<MailOutlineIcon />} style={{ color: '#bde7bd' }} {...props} />
export const Delete = (props) => <IconAction tooltip="Supprimer" icon={<DeleteIcon />} color="secondary" {...props} />
export const Close = (props) => <IconAction tooltip="Fermer" icon={<CloseIcon />} {...props} />
export const View = (props) => <IconAction tooltip="Examiner" icon={<SearchIcon />} {...props} />
export const Back = (props) => <IconAction tooltip="Retour en arrière" icon={<KeyboardBackspaceIcon />} {...props} />
export const Reset = (props) => <IconAction tooltip="Réinitialiser" icon={<ReplayIcon />} {...props} />
export const Clear = (props) => <IconAction tooltip="Effacer" icon={<ClearIcon />} {...props} />


export const Edit = ({ tooltip, action }) => <IconAction tooltip={tooltip} action={action} icon={<EditIcon />} />
export const Duplicate = ({ tooltip, action }) => <IconAction tooltip={tooltip} action={action} icon={<FileCopyIcon />} />
export const Down = ({ tooltip, action }) => <IconAction tooltip={tooltip} action={action} icon={<ArrowDownwardIcon />} />
export const Up = ({ tooltip, action }) => <IconAction tooltip={tooltip} action={action} icon={<ArrowUpwardIcon />} />
export const Add = ({ tooltip, action }) => <IconAction tooltip={tooltip} action={action} icon={<AddCircleOutlineIcon />} />
