import React, { Fragment } from 'react'

import {
    Grid,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'

import { storage } from '../firebase/config'

const QuestionText = ({
    question, answer, setAnswer
}) => {
    const [image, setImage] = React.useState(false)

    function getImageURL() {
        console.log('getimageurl', question)

        const imgRef = storage.ref().child(`images/${question.picture}`)
        imgRef.getDownloadURL()
            .then(url => setImage(url))
            .catch(error => console.error(error))
    }

    React.useEffect(() => {
        setImage(false)
        if (question?.hasOwnProperty('picture'))
            getImageURL()
    }, [question])

    return (
        <Fragment>
            <Grid container>
                <Grid item xs={12} sm={image ? 9 : 12}>
                    {question.hasOwnProperty('text') &&
                        <Typography style={{ marginBottom: '1rem' }}>
                            {question.text}
                        </Typography>
                    }
                    <Typography>
                        {question.question}
                    </Typography>
                </Grid>
                {image &&
                    <Grid item xs={12} sm={3}>
                        <div style={{ paddingLeft: '1rem' }}>
                            <img style={{ width: "100%" }} src={image} alt={question.picture} />
                        </div>
                    </Grid>
                }
            </Grid>
            <List component="ul">
                {Object.keys(question.answers).sort().map((key, i) =>
                    <ListItem key={i} button onClick={() => setAnswer(key)} style={{ borderRadius: '5px', backgroundColor: `${answer === key ? '#b6edb6' : 'inherit'}` }}>
                        <ListItemIcon>{answer === key ? <CheckIcon /> : <CheckBoxOutlineBlankIcon />}</ListItemIcon>
                        <ListItemText>{question.answers[key]}</ListItemText>
                    </ListItem>
                )}
            </List>
        </Fragment >
    )
}

export default QuestionText