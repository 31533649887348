import React, { useState, Fragment, useEffect } from 'react'
import { useStoreState } from 'easy-peasy'

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Paper,
    Typography,
    Chip,
    Grid,
} from '@material-ui/core'
import DoneIcon from '@material-ui/icons/Done'

const getStatus = (test) => {
    if (test.answers.length === 0) return <Chip label="En attente" style={{ backgroundColor: '#e8f4fd' }} />
    else if (test.status === 'ongoing') return <Chip label="En cours" style={{ backgroundColor: '#fff4e5' }} />
    else if (test.status === 'completed') return <Chip label="Terminé" style={{ backgroundColor: '#edf7ed' }} />
    else return <Chip label="N/A" style={{ backgroundColor: '#fdecea' }} />
}

const Tests = () => {
    const state = useStoreState(state => state.organisation)

    const [order, setOrder] = useState('desc')
    const [orderBy, setOrderBy] = useState('update')
    const [status, setStatus] = useState(false)
    const [filter, setFilter] = useState({ quest: false, org: false })
    const [filteredAndSorted, setTests] = useState(state.tests)

    const handleRequestSort = (property) => (event) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    useEffect(() => {
        const getField = (el, orderBy) => {
            switch (orderBy) {
                case 'user':
                    return el.user.email
                case 'questionnaire':
                    return el.questionnaire.name
                case 'update':
                    return el.updated.seconds
                default:
                    return el.user.email
            }
        }

        const descendingComparator = (a, b, orderBy) => {
            if (getField(b, orderBy) < getField(a, orderBy)) {
                return -1
            }
            if (getField(b, orderBy) > getField(a, orderBy)) {
                return 1
            }
            return 0
        }

        const comparator = order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy)

        const stabilizedThis = state.tests.map((el, index) => [el, index])

        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0])
            if (order !== 0) return order
            return a[1] - b[1]
        })

        setTests(stabilizedThis.map((el) => el[0]).filter(el =>
            (!status ? true : el.status === status) &&
            (!filter.quest ? true : el.questionnaire.name === filter.quest)
        ))
    }, [state.tests, order, orderBy, status, filter])

    return (
        <div>
            <Grid container spacing={1} style={{ margin: '1rem 0' }}>
                <Grid item><Chip label="En attente" style={{ backgroundColor: '#e8f4fd' }} onClick={() => setStatus('ready')} icon={status === 'ready' ? <DoneIcon /> : null} /></Grid>
                <Grid item><Chip label="En cours" style={{ backgroundColor: '#fff4e5' }} onClick={() => setStatus('ongoing')} icon={status === 'ongoing' ? <DoneIcon /> : null} /></Grid>
                <Grid item><Chip label="Terminé" style={{ backgroundColor: '#edf7ed' }} onClick={() => setStatus('completed')} icon={status === 'completed' ? <DoneIcon /> : null} /></Grid>
                <Grid item><Chip label="Tous" style={{ backgroundColor: '#fdecea' }} onClick={() => setStatus(false)} icon={status === false ? <DoneIcon /> : null} /></Grid>
                {!!filter.quest && <Grid item><Chip label={`Questionnaire: ${filter.quest}`} style={{ backgroundColor: '#ddd' }} onDelete={() => setFilter({ ...filter, quest: false })} /></Grid>}
            </Grid>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sortDirection={orderBy === 'user' ? order : false}>
                                <TableSortLabel
                                    active={orderBy === 'user'}
                                    direction={orderBy === 'user' ? order : 'asc'}
                                    onClick={handleRequestSort('user')}
                                >
                                    Utilisateur
                                {orderBy === 'user' ? (
                                        <span style={{
                                            border: 0,
                                            clip: 'rect(0 0 0 0)',
                                            height: 1,
                                            margin: -1,
                                            overflow: 'hidden',
                                            padding: 0,
                                            position: 'absolute',
                                            top: 20,
                                            width: 1
                                        }}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                    ) : null}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sortDirection={orderBy === 'questionnaire' ? order : false}>
                                <TableSortLabel
                                    active={orderBy === 'questionnaire'}
                                    direction={orderBy === 'questionnaire' ? order : 'asc'}
                                    onClick={handleRequestSort('questionnaire')}
                                >
                                    Questionnaire
                                {orderBy === 'questionnaire' ? (
                                        <span style={{
                                            border: 0,
                                            clip: 'rect(0 0 0 0)',
                                            height: 1,
                                            margin: -1,
                                            overflow: 'hidden',
                                            padding: 0,
                                            position: 'absolute',
                                            top: 20,
                                            width: 1
                                        }}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                    ) : null}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sortDirection={orderBy === 'update' ? order : false}>
                                <TableSortLabel
                                    active={orderBy === 'update'}
                                    direction={orderBy === 'update' ? order : 'asc'}
                                    onClick={handleRequestSort('update')}
                                >
                                    Dernière mise à jour
                                {orderBy === 'update' ? (
                                        <span style={{
                                            border: 0,
                                            clip: 'rect(0 0 0 0)',
                                            height: 1,
                                            margin: -1,
                                            overflow: 'hidden',
                                            padding: 0,
                                            position: 'absolute',
                                            top: 20,
                                            width: 1
                                        }}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                    ) : null}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.tests.length === 0 && <TableRow><TableCell colSpan="4">Il n'existe pas encore de tests en cours pour votre organisation.</TableCell></TableRow>}
                        {state.tests.length !== 0 && filteredAndSorted.length === 0 && <TableRow><TableCell colSpan="4">Aucun test ne correspond au filtre appliqué.</TableCell></TableRow>}
                        {filteredAndSorted.map((test, i) => (
                            <Fragment key={i}>
                                <TableRow hover>
                                    <TableCell>
                                        <Typography component="p">{test.user.email}</Typography>
                                        <Typography variant="caption">Age: {test.user.age} – Genre: {test.user.sex?.toUpperCase()}</Typography>
                                    </TableCell>
                                    <TableCell onClick={() => setFilter({ quest: test.questionnaire.name })} style={{ cursor: 'pointer' }}>{test.questionnaire.name}</TableCell>
                                    <TableCell>{new Date(test.updated.seconds * 1000).toLocaleDateString("fr-BE")}</TableCell>
                                    <TableCell>{getStatus(test)}</TableCell>
                                </TableRow>
                            </Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default Tests