import React, { useState, Fragment, useEffect } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Paper,
    Tooltip,
    IconButton,
    Typography,
    TextField,
    Chip,
    Badge,
    Grid,
} from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import DoneIcon from '@material-ui/icons/Done'

import { Delete, Reset } from '../../shared/ActionIcon'

const getStatus = (test) => {
    if (test.answers.length === 0) return <Chip label="En attente" style={{ backgroundColor: '#e8f4fd' }} />
    else if (test.status === 'ongoing') return <Chip label="En cours" style={{ backgroundColor: '#fff4e5' }} />
    else if (test.status === 'completed') return <Chip label="Terminé" style={{ backgroundColor: '#edf7ed' }} />
    else return <Chip label="N/A" style={{ backgroundColor: '#fdecea' }} />
}

const ListTests = () => {
    // const actions = useStoreActions(actions => actions.admin)
    // const tests = useStoreState(state => state.admin.tests)

    const test = useStoreActions(actions => actions.test)
    const state = useStoreState(state => state.test)

    const [select, setSelect] = useState(-1)
    const [order, setOrder] = useState('desc')
    const [orderBy, setOrderBy] = useState('update')
    const [status, setStatus] = useState(false)
    const [filter, setFilter] = useState({ quest: false, org: false })
    // const [filter, setFilter] = useState(false)
    const [filteredAndSorted, setTests] = useState(state.list)

    const handleRequestSort = (property) => (event) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    }

    const showHide = (i) => {
        if (select === i) setSelect(-1)
        else setSelect(i)
    }

    const reset = (testId) => {
        if (window.confirm('Réinitialiser le test à zéro ?'))
            test.reset(testId)
    }

    const remove = (testId) => {
        if (window.confirm('Supprimer le test ?'))
            test.delete(testId)
    }

    useEffect(() => {
        const getField = (el, orderBy) => {
            switch (orderBy) {
                case 'user':
                    return el.user.email
                case 'questionnaire':
                    return el.questionnaire.name
                case 'organisationo':
                    return el.organisation.name
                case 'update':
                    return el.updated.seconds
                default:
                    return el.user.email
            }
        }

        const descendingComparator = (a, b, orderBy) => {
            if (getField(b, orderBy) < getField(a, orderBy)) {
                return -1
            }
            if (getField(b, orderBy) > getField(a, orderBy)) {
                return 1
            }
            return 0
        }

        const comparator = order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy)

        const stabilizedThis = state.list.map((el, index) => [el, index])

        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0])
            if (order !== 0) return order
            return a[1] - b[1]
        })

        setTests(stabilizedThis.map((el) => el[0]).filter(el =>
            (!status ? true : el.status === status) &&
            (!filter.quest ? true : el.questionnaire.name === filter.quest) &&
            (!filter.org ? true : el.organisation.name === filter.org)
        ))
    }, [state.list, order, orderBy, status, filter])

    return (
        <div>
            <Grid container spacing={1} style={{ marginBottom: '1rem' }}>
                <Grid item><Chip label="En attente" style={{ backgroundColor: '#e8f4fd' }} onClick={() => setStatus('ready')} icon={status === 'ready' ? <DoneIcon /> : null} /></Grid>
                <Grid item><Chip label="En cours" style={{ backgroundColor: '#fff4e5' }} onClick={() => setStatus('ongoing')} icon={status === 'ongoing' ? <DoneIcon /> : null} /></Grid>
                <Grid item><Chip label="Terminé" style={{ backgroundColor: '#edf7ed' }} onClick={() => setStatus('completed')} icon={status === 'completed' ? <DoneIcon /> : null} /></Grid>
                <Grid item><Chip label="Tous" style={{ backgroundColor: '#fdecea' }} onClick={() => setStatus(false)} icon={status === false ? <DoneIcon /> : null} /></Grid>
                {!!filter.quest && <Grid item><Chip label={`Questionnaire: ${filter.quest}`} style={{ backgroundColor: '#ddd' }} onDelete={() => setFilter({ ...filter, quest: false })} /></Grid>}
                {!!filter.org && <Grid item><Chip label={`Organisation: ${filter.org}`} style={{ backgroundColor: '#ddd' }} onDelete={() => setFilter({ ...filter, org: false })} /></Grid>}
            </Grid>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sortDirection={orderBy === 'user' ? order : false}>
                                <TableSortLabel
                                    active={orderBy === 'user'}
                                    direction={orderBy === 'user' ? order : 'asc'}
                                    onClick={handleRequestSort('user')}
                                >
                                    Utilisateur
                                {orderBy === 'user' ? (
                                        <span style={{
                                            border: 0,
                                            clip: 'rect(0 0 0 0)',
                                            height: 1,
                                            margin: -1,
                                            overflow: 'hidden',
                                            padding: 0,
                                            position: 'absolute',
                                            top: 20,
                                            width: 1
                                        }}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                    ) : null}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sortDirection={orderBy === 'questionnaire' ? order : false}>
                                <TableSortLabel
                                    active={orderBy === 'questionnaire'}
                                    direction={orderBy === 'questionnaire' ? order : 'asc'}
                                    onClick={handleRequestSort('questionnaire')}
                                >
                                    Questionnaire
                                {orderBy === 'questionnaire' ? (
                                        <span style={{
                                            border: 0,
                                            clip: 'rect(0 0 0 0)',
                                            height: 1,
                                            margin: -1,
                                            overflow: 'hidden',
                                            padding: 0,
                                            position: 'absolute',
                                            top: 20,
                                            width: 1
                                        }}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                    ) : null}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sortDirection={orderBy === 'organisation' ? order : false}>
                                <TableSortLabel
                                    active={orderBy === 'organisation'}
                                    direction={orderBy === 'organisation' ? order : 'asc'}
                                    onClick={handleRequestSort('organisation')}
                                >
                                    Organisation
                                {orderBy === 'organisation' ? (
                                        <span style={{
                                            border: 0,
                                            clip: 'rect(0 0 0 0)',
                                            height: 1,
                                            margin: -1,
                                            overflow: 'hidden',
                                            padding: 0,
                                            position: 'absolute',
                                            top: 20,
                                            width: 1
                                        }}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                    ) : null}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sortDirection={orderBy === 'update' ? order : false}>
                                <TableSortLabel
                                    active={orderBy === 'update'}
                                    direction={orderBy === 'update' ? order : 'asc'}
                                    onClick={handleRequestSort('update')}
                                >
                                    Dernière mise à jour
                                {orderBy === 'update' ? (
                                        <span style={{
                                            border: 0,
                                            clip: 'rect(0 0 0 0)',
                                            height: 1,
                                            margin: -1,
                                            overflow: 'hidden',
                                            padding: 0,
                                            position: 'absolute',
                                            top: 20,
                                            width: 1
                                        }}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                    ) : null}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredAndSorted.map((test, i) => (
                            <Fragment key={i}>
                                <TableRow hover>
                                    <TableCell>
                                        <Typography component="p">{test.user.email}</Typography>
                                        <Typography variant="caption">Age: {test.user.age} – Genre: {test.user.sex?.toUpperCase()}</Typography>
                                    </TableCell>
                                    <TableCell onClick={() => setFilter({ ...filter, quest: test.questionnaire.name })} style={{ cursor: 'pointer' }}>{test.questionnaire.name}</TableCell>
                                    <TableCell onClick={() => setFilter({ ...filter, org: test.organisation.name })} style={{ cursor: 'pointer' }}>{test.organisation.name}</TableCell>
                                    <TableCell>{new Date(test.updated.seconds * 1000).toLocaleDateString("fr-BE")}</TableCell>
                                    <TableCell>{getStatus(test)}</TableCell>
                                    <TableCell>
                                        {test.status === 'completed' &&
                                            <Fragment>
                                                {test.hasOwnProperty('resetCount') &&
                                                    <Badge badgeContent={test.resetCount} color="secondary" size="small">
                                                        <Reset action={() => reset(test.id)} />
                                                    </Badge>
                                                }
                                                {!test.hasOwnProperty('resetCount') &&
                                                    <Reset action={() => reset(test.id)} />
                                                }
                                                <Tooltip title={`${select === i ? 'Cacher' : 'Montrer'} les réponses`}>
                                                    <IconButton size="small" onClick={() => showHide(i)}>
                                                        {select !== i && <VisibilityIcon />}
                                                        {select === i && <VisibilityOffIcon />}
                                                    </IconButton>
                                                </Tooltip>
                                            </Fragment>
                                        }
                                        <Delete action={() => remove(test.id)} />
                                    </TableCell>
                                </TableRow>
                                {select === i &&
                                    <TableRow style={{ backgroundColor: 'lightgoldenrodyellow' }}>
                                        <TableCell colSpan={5} >
                                            <TextField size="small" variant="outlined" fullWidth value={rawAnswers(test)} style={{ backgroundColor: 'white' }} />
                                        </TableCell>
                                    </TableRow>
                                }
                            </Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default ListTests

const rawAnswers = (test) => {
    let rawAnswers = []
    test.answers.forEach(ans => {
        let rawAnswer = [0, 0, 0, 0, 0]
        const index = ans.slice(-1)
        rawAnswer[+index - 1] = 1
        rawAnswers.push(rawAnswer)
    })
    return rawAnswers.toString()
}
