import React, { Fragment } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'

import TableOptions from '../../shared/TableOptions'

import AddCredits from './AddCredits'

const OrganisationCredits = () => {
    const organisation = useStoreActions(actions => actions.organisation)
    const state = useStoreState(state => state.organisation)
    const auth = useStoreState(state => state.auth)

    return (
        <Fragment>
            <AddCredits />
            <TableContainer style={{ margin: '1rem 0' }} component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Questionnaire</TableCell>
                            <TableCell>Crédit utilisé</TableCell>
                            <TableCell>Crédit restant</TableCell>
                            <TableCell width="1px"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!state.current?.credits && <TableRow><TableCell colSpan="4">Votre organisation n'a accès à aucun questionnaire en ce moment.</TableCell></TableRow>}
                        {state.current?.credits && Object.entries(state.current.credits).map(([key, credit]) => (
                            <TableRow key={key}>
                                <TableCell>
                                    {credit.name}
                                </TableCell>
                                <TableCell>
                                    {credit.used}
                                </TableCell>
                                <TableCell>
                                    {credit.left}
                                </TableCell>
                                <TableCell align="right">
                                    {/* <View /> */}
                                    {/* <Delete action={() => actions.removeMember({ userId: member.id, orgId })} /> */}
                                    {auth.isAdmin &&
                                        <TableOptions options={[
                                            { label: "Supprimer", icon: <DeleteIcon />, action: () => organisation.removeQuestionnaire({ organisationId: state.current.id, questionnaireId: key }) },
                                        ]} />
                                    }
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Fragment>
    )
}

export default OrganisationCredits