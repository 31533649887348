import React from 'react'

import {
    Button as MuiButton,
} from '@material-ui/core'

const Button = ({ label, onClick, startIcon = false, ...rest }) => {
    return (
        <MuiButton
            size="small"
            variant="contained"
            color="primary"
            onClick={onClick}
            startIcon={startIcon}
            {...rest}
        >
            {label}
        </MuiButton>
    )
}

export default Button