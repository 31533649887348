import React, { useEffect, useState } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'

import {
    Grid,
    Typography,
    TextField,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

import SelectQuestionnaire from '../../shared/SelectQuestionnaire'
import Button from '../../shared/Button'

const AddCredits = () => {
    const organisation = useStoreActions(actions => actions.organisation)
    const questionnaire = useStoreActions(actions => actions.questionnaire)
    const state = useStoreState(state => state)

    const [questObj, setQuest] = useState(null)
    const [nbCredits, setCredits] = useState(1)

    const onSelect = (questionnaire) => setQuest(questionnaire)
    const onClick = () => {
        if (questObj !== null && Number.isInteger(nbCredits))
            organisation.addCredits({ organisationId: state.organisation.current.id, questionnaire: questObj, nbCredits: nbCredits })
    }

    useEffect(() => {
        const unsubscribe = questionnaire.listAll()
        return () => unsubscribe()
    }, [questionnaire])

    if (!state.auth.isAdmin) return null

    return (
        <Grid container alignItems="center" spacing={2}>
            <Grid item><Typography>Ajout de crédits:</Typography></Grid>
            <Grid item><SelectQuestionnaire onChange={onSelect} style={{ width: '200px' }} /></Grid>
            <Grid item><TextField value={nbCredits} label="Credits" type="number" variant="outlined" onChange={e => setCredits(+e.target.value)} size="small" /></Grid>
            <Grid item><Button label="Ajouter des crédits" onClick={onClick} startIcon={<AddIcon />} /></Grid>
        </Grid>
    )
}

export default AddCredits