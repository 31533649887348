import React, { useState, Fragment } from 'react'
import { useStoreActions } from 'easy-peasy'

import {
    Divider
} from '@material-ui/core'

import Panel from '../../shared/Panel'
import SelectUser from '../../shared/SelectUser'
import SelectQuestionnaire from '../../shared/SelectQuestionnaire'
import SelectOrganization from '../../shared/SelectOrganization'

const AssignTest = () => {
    return (
        <Fragment>
            <AssignToUser />
            <Divider style={{ margin: '2rem 0' }} />
            <AssignToOrganization />
        </Fragment>
    )
}

export default AssignTest

const AssignToUser = () => {
    const actions = useStoreActions(actions => actions.admin)

    const initValues = { userId: '', questionnaireId: '' }
    const [values, setValues] = useState(initValues)
    const [status, setStatus] = useState(null)

    const onChange = (e) => setValues({ ...values, [e.target.name]: e.target.value })

    const onClick = () => {
        setStatus('loading')
        actions.assignToUser(values)
            .then(message => setStatus(message))
            .catch(error => setStatus(error))
    }

    return (
        <Panel title="Assigner un test à un utilisateur :" button="Assigner le test" status={status} onSubmit={onClick}>
            <SelectUser onChange={onChange} />
            <SelectQuestionnaire onChange={onChange} style={{ marginTop: '1rem' }} />
        </Panel>
    )
}

const AssignToOrganization = () => {
    const actions = useStoreActions(actions => actions.admin)

    const initValues = { orgId: '', questionnaireId: '' }
    const [values, setValues] = useState(initValues)
    const [status, setStatus] = useState(null)

    const onChange = (e) => setValues({ ...values, [e.target.name]: e.target.value })

    const onClick = () => {
        setStatus('loading')
        actions.assignToOrganization(values)
            .then(message => setStatus(message))
            .catch(error => setStatus(error))
    }

    return (
        <Panel title="Assigner un test à une organisation :" button="Assigner le test" status={status} onSubmit={onClick}>
            <SelectOrganization onChange={onChange} />
            <SelectQuestionnaire onChange={onChange} style={{ marginTop: '1rem' }} />
        </Panel>
    )
}