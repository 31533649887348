import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useStoreRehydrated, useStoreState } from 'easy-peasy'

const AdminRoute = ({ component: Component, ...rest }) => {
    const rehydrated = useStoreRehydrated()
    const auth = useStoreState(state => state.auth)

    if (!rehydrated) return null

    return (
        <Route {...rest} render={props => (
            auth.isLogged && auth.isAdmin ?
                <Component {...props} />
                : <Redirect to="/login" />
        )} />
    )
}

export default AdminRoute