import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useStoreActions } from 'easy-peasy'

const SignOut = ({
    history,
}) => {
    const actions = useStoreActions(actions => actions.auth)

    useEffect(() => {
        actions.logOut()
        history.push('/')
    }, [actions, history])

    return <div>Signing out..</div>
}

export default withRouter(SignOut)