import React, { useEffect, useState, Fragment } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@material-ui/core'

import { View, Delete } from '../../shared/ActionIcon'

const ListQuestions = () => {
    const question = useStoreActions(actions => actions.question)
    const state = useStoreState(state => state.question)

    const [view, setView] = useState(-1)
    const showHide = (id) => {
        if (view === id) setView(-1)
        else setView(id)
    }

    useEffect(() => {
        const unsubscribe = question.listAll()
        return () => unsubscribe()
    }, [question])

    return (
        <TableContainer style={{ margin: '1rem 0' }} component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Question</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {state.list.map((q, i) => (
                        <Fragment key={i}>
                            <TableRow>
                                <TableCell>{q.id}</TableCell>
                                <TableCell>{q.question.slice(0, 100)}...</TableCell>
                                <TableCell align="right">
                                    <View action={() => showHide(q.id)} />
                                    <Delete action={() => question.delete(q.id)} />
                                </TableCell>
                            </TableRow>
                            {view === q.id &&
                                <TableRow>
                                    <TableCell colSpan={3} >
                                        <div style={{ marginBottom: '0.5rem' }}>{q.question}</div>
                                        <div style={{ marginLeft: '1rem' }}>
                                            {Object.entries(q.answers).map(([key, answer], j) =>
                                                <div key={j}><b>{key}</b> – {answer}</div>
                                            )}
                                        </div>
                                    </TableCell>
                                </TableRow>
                            }
                        </Fragment>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default ListQuestions