import React, { useState, Fragment } from 'react'
import { useStoreActions } from 'easy-peasy'

import {
    TextField,
    Button,
    Collapse,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

import Panel from '../../shared/Panel'

const CreateUser = () => {
    const actions = useStoreActions(actions => actions.user)

    const initValues = { email: '' }
    const [values, setValues] = useState(initValues)
    const [status, setStatus] = useState(null)
    const [open, setOpen] = useState(false)

    const onChange = (e) => setValues({ ...values, [e.target.name]: e.target.value })
    const onClose = () => { setOpen(false); setValues(initValues); setStatus(null) }

    const onCreate = () => {
        const validEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email)

        if (!validEmail) setStatus('invalid_email')
        else {
            setStatus('loading')
            createUser()
        }
    }

    const createUser = () => {
        actions.createOne(values)
            .then(result => {
                setStatus(result.data)
                setValues(initValues)
            })
            .catch(error => {
                console.log(error.message)
                setStatus(error.message)
            })
    }

    // useEffect(() => {
    //     const unsubscribe = actions.listOrganizations()
    //     return () => unsubscribe()
    // }, [actions])

    return (
        <Fragment>
            <Collapse in={!open}>
                <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => setOpen(true)}
                    startIcon={<AddIcon />}
                    style={{ margin: '1rem 0' }}
                >Créer un utilisateur</Button>
            </Collapse>
            <Collapse in={open}>
                <Panel title="Nouvel utilisateur :" button="Créer l'utilisateur" status={status} onClose={onClose} onSubmit={onCreate}>
                    <TextField name="email" label="Email" placeholder="Adresse email de l'utilisateur" value={values.email} variant="outlined" fullWidth onChange={onChange} size="small" />
                </Panel>
            </Collapse>
        </Fragment >
    )
}

export default CreateUser