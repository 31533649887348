import React, { useState, Fragment } from 'react'
import { useStoreActions } from 'easy-peasy'

import {
    TextField,
    Button,
    Collapse,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

import Panel from '../../shared/Panel'

const CreateQuestionnaire = () => {
    const questionnaire = useStoreActions(actions => actions.questionnaire)

    const initValues = { name: '', questions: '' }
    const [values, setValues] = useState(initValues)
    const [status, setStatus] = useState(null)
    const [open, setOpen] = useState(false)

    const onChange = (e) => setValues({ ...values, [e.target.id]: e.target.value })
    const onClose = () => { setOpen(false); setValues(initValues); setStatus(null) }

    const onClick = () => {
        setStatus('loading')
        questionnaire.create(values)
            .then(() => setStatus('Le questionnaire a été créé.'))
            .catch(error => setStatus(error))
    }

    return (
        <Fragment>
            <Collapse in={!open}>
                <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => setOpen(true)}
                    startIcon={<AddIcon />}
                    style={{ margin: '1rem 0' }}
                >Créer un questionnaire</Button>
            </Collapse>
            <Collapse in={open}>
                <Panel title="Créer un questionnaire :" button="Créer le questionnaire" status={status} onClose={onClose} onSubmit={onClick}>
                    <TextField id="name" label="Nom" value={values.name} variant="outlined" fullWidth onChange={onChange} size="small" />
                    <TextField style={{ marginTop: '1rem' }} id="questions" label="Liste des questions" value={values.questions} variant="outlined" fullWidth onChange={onChange} placeholder="Q011,Q029,Q009,Q220, ..." size="small" />
                </Panel>
            </Collapse>
        </Fragment>
    )
}

export default CreateQuestionnaire